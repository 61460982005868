<template>
  <ImportEnterprise />
</template>

<script>
import ImportEnterprise from '@/modules/file-import/FileImportEnterprise.vue'

export default {
  components: { ImportEnterprise },
}
</script>
