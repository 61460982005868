import { render, staticRenderFns } from "./FileImportLicenseType.vue?vue&type=template&id=32866bdc&scoped=true&"
import script from "./FileImportLicenseType.vue?vue&type=script&lang=js&"
export * from "./FileImportLicenseType.vue?vue&type=script&lang=js&"
import style0 from "./FileImportLicenseType.vue?vue&type=style&index=0&id=32866bdc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32866bdc",
  null
  
)

export default component.exports