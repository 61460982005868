<template>
  <ImportLicensingTable />
</template>

<script>
import ImportLicensingTable from '@/modules/file-import/FileImportLicensingTable.vue'

export default {
  components: { ImportLicensingTable },
}
</script>
