<template>
  <ImportProvider />
</template>

<script>
import ImportProvider from '@/modules/file-import/FileImportProvider.vue'

export default {
  components: { ImportProvider },
}
</script>
