<script>/* eslint-disable */</script>
<template>
    <div>
        
        <div class="status" :class="getStatusColor">
            <p v-if="statusPlan === 'pending'">
                Status do pedido: Pagamento pendente ou em processamento.
                <span v-if="fatura && statusPlan === 'pending'">
                    <br>
                    <small>Link da fatura: <a :href="fatura">{{ fatura }}</a></small>
                </span>
            </p>
            <p v-if="statusPlan === 'canceled'">Status do pedido: Cancelado <br> <small>A data de vencimento da fatura foi
                    atingida e/ou pagamento não foi efetuado.</small></p>
            <p v-if="statusPlan === 'paid'">Assinatura do plano concluído com sucesso! Data de renovação: {{
                this.expire_date }}
            </p>
        </div>
        <div class="cartoes-container">
            <button class="cartao" :class="{ selecionado: planoSelecionado === 1, impossivel: planoAtual === 'plano_1' }"
                @click="selecionarPlano(1)" :disabled="planoAtual === 'plano_1' && statusPlan != 'canceled'">
                <h3>VerdeSys 1.0</h3>
                <hr class="linha-verde">
                <p>Acesso para até 3 usuários</p>
                <hr class="linha-verde">
                <p>Até 10 CNPJs</p>
                <hr class="linha-verde">
                <p class="preco">R$ 250,<small>00/mês</small></p>
                <hr class="linha-verde">
                <ul class="lista-itens">
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Cadastros de
                        Licenças e Condicionantes
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Cadastro de
                        Notificações
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Cadastro de
                        Autorizações
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Acesso Responsivo
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Importação de
                        Planilhas
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Relatórios
                    </li>
                    <li>
                        <span class="icone-check"> <img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Gestão de Resíduos
                    </li>
                </ul>
                <div class="linha-verde"></div>
                <div class="btn-selecionar">{{ planoAtual === 'plano_1' ? 'Plano Atual' : 'Selecionar' }}</div>
            </button>

            <button class="cartao" :class="{ selecionado: planoSelecionado === 2, impossivel: planoAtual === 'plano_2' }"
                @click="selecionarPlano(2)" :disabled="planoAtual === 'plano_2' && statusPlan != 'canceled'">
                <h3>VerdeSys 2.0</h3>
                <hr class="linha-verde">
                <p>Acesso para até 5 usuários</p>
                <hr class="linha-verde">
                <p>Até 20 CNPJs</p>
                <hr class="linha-verde">
                <p class="preco">R$ 390,<small>00/mês</small></p>
                <hr class="linha-verde">
                <ul class="lista-itens">
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Cadastros de
                        Licenças e Condicionantes
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Cadastro de
                        Notificações
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Cadastro de
                        Autorizações
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Acesso Responsivo
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Importação de
                        Planilhas
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Relatórios
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Gestão de Resíduos
                    </li>
                </ul>
                <div class="linha-verde"></div>
                <div class="btn-selecionar">{{ planoAtual === 'plano_2' ? 'Plano Atual' : 'Selecionar' }}</div>
            </button>

            <button class="cartao" :class="{ selecionado: planoSelecionado === 3, impossivel: planoAtual === 'plano_3' }"
                @click="selecionarPlano(3)" :disabled="planoAtual === 'plano_3' && statusPlan != 'canceled'">
                <h3>VerdeSys 3.0</h3>
                <hr class="linha-verde">
                <p>Acesso para até 10 usuários</p>
                <hr class="linha-verde">
                <p>Até 30 CNPJs</p>
                <hr class="linha-verde">
                <p class="preco">R$ 750, <small>00/mês</small></p>
                <hr class="linha-verde">
                <ul class="lista-itens">
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Cadastros de
                        Licenças e Condicionantes
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Cadastro de
                        Notificações
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Cadastro de
                        Autorizações
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Acesso Responsivo
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Importação de
                        Planilhas
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Relatórios
                    </li>
                    <li>
                        <span class="icone-check"><img class="verify-icon" width="20px"
                                src="https://img.icons8.com/?size=512&id=21068&format=png" alt=""></span>Gestão de Resíduos
                    </li>
                </ul>
                <div class="linha-verde"></div>
                <div class="btn-selecionar">{{ planoAtual === 'plano_3' ? 'Plano Atual' : 'Selecionar' }}</div>
            </button>
        </div>

        <div class="containerbtn">
            <button class="salvar" @click="openModal" :disabled="planoSelecionado === null">

                {{ planoSelecionado === null ? 'Selecione um plano' : 'Ir para checkout' }}
            </button>
        </div>

        <div v-if="modalOpen" class="modal">
            <div class="modal-content">
                <div>
                    <span class="close" @click="closeModal">&times;</span>
                    <h5>Checkout para {{ this.planoSelecionado == 1 ? 'VerdeSys 1.0' : this.planoSelecionado == 2 ?
                        'VerdeSys 2.0' : 'VerdeSys 3.0' }}
                    </h5>
                </div>


                <div class="input-grid">
                    <div class="input-group">
                        <label for="nome"><small>Nome:</small></label>
                        <input :value="this.user.name" id="nome" type="text" disabled>
                    </div>

                    <div class="input-group">
                        <label for="cpf_cnpj"> <small>CPF/CNPJ:</small></label>
                        <input id="cpf_cnpj" type="text" disabled :value="this.user.cpf_cnpj">
                    </div>

                    <div class="input-group">
                        <label for="email"> <small>Email:</small></label>
                        <input id="email" type="email" disabled :value="this.user.email">
                    </div>

                    <div class="input-group">
                        <label for="documento_identificacao"><small>Documento de Identificação:</small></label>
                        <input id="documento_identificacao" type="text" disabled :value="this.user.documento_identificacao">
                    </div>
                </div>

                <div class="input-grid">
                    <div class="input-group">
                        <label for="orgao_emissor"><small>Órgão Emissor:</small></label>
                        <input id="orgao_emissor" type="text" disabled :value="this.user.orgao_emissor">
                    </div>

                    <div class="input-group">
                        <label for="celular"><small>Celular:</small></label>
                        <input id="celular" type="tel" disabled :value="this.user.celular">
                    </div>
                </div>
                <div class="gridSave" style="
                display: inline-flex;
                justify-content: space-between;
                width: 100%;               
                ">
                    <a class="salvar-btn" href="/minha-conta" style=" background-color: orange;">
                        Editar
                    </a>

                    <button class="salvar-btn" @click="salvarPlano" :disabled="planoSelecionado === null">
                        Assinar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
  
  
<script>
import axios from "axios";
import api from "@/libs/axios";
import {getAccountById} from "@/services/accounts";
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            // accountIsActived: null,
            planoSelecionado: null,
            subs_iugu_id: "",
            planoAtual: null,
            statusPlan: null,
            fatura: null,
            expire_date: null,
            modalOpen: false,
            id_user: null,

            user: {
                name: "",
                cpf_cnpj: "",
                email: "",
                documento_identificacao: "",
                orgao_emissor: "",
                celular: "",
            },

        };
    },

    mounted() {
        const userIdAccount = JSON.parse(localStorage.getItem("id_account_verdesys"));

        console.log('plano atual', userIdAccount)
        api.get(`/accounts/${userIdAccount}`).then((response) => {

          this.user.cpf_cnpj  = response.data.cpf;
          console.log('isLegalPersonkkkkkk', this.user.cpf_cnpj)     
      });

        
        const localStorageUser = JSON.parse(localStorage.getItem("user"));
        this.user.name = localStorageUser.full_name;
        
        this.user.email = localStorageUser.email;
        this.user.documento_identificacao = localStorageUser.identification_document;
        this.user.orgao_emissor = localStorageUser.document_issuer;
        this.user.celular = localStorageUser.telephone;
        this.id_user = localStorageUser.id;

        

        this.subs_iugu_id = JSON.parse(localStorage.getItem("user_infos")).id;
        this.planoAtual = JSON.parse(localStorage.getItem("user_infos")).plan_identifier;
        this.statusPlan = JSON.parse(localStorage.getItem("user_infos")).recent_invoices[0].status;
        this.fatura = JSON.parse(localStorage.getItem("user_infos")).recent_invoices[0].secure_url;
        this.expire_date = new Date(JSON.parse(localStorage.getItem("user_infos")).expires_at).toLocaleDateString("pt-BR");
        const dateFormated = new Date(this.expire_date);



        if (this.id_user != 1) {
            this.$router.push("/minha-conta");
            console.log("não é admin", this.id_user);
        } else {
            console.log("é admin", this.id_user);
        }
    },

    computed: {
        getStatusColor() {
            if (this.statusPlan === "pending") {
                return "orange";
            } else if (this.statusPlan === "canceled") {
                return "red";
            } else if (this.statusPlan === "paid") {
                return "green";
            } else {
                return "";
            }
        },
    },
    methods: {

        createdMessage() {
            this.toast({
                title: 'Plano salvo com sucesso',
                text: 'O plano foi salvo com sucesso! A página será recarregada para aplicar as alterações',
                variant: 'success'
            })
        },

        unsuccessfulMessage() {
            this.toast({
                title: 'Erro ao salvar o plano',
                text: 'Ocorreu um erro ao assinar o plano, tente novamente mais tarde',
                variant: 'danger'
            })
        },

        notPossibleMessage() {
            this.toast({
                title: 'somente o administrador pode alterar o plano',
                text: 'contate o administrador para alterar o plano',
                variant: 'danger'
            })
        },


        openModal() {
            this.modalOpen = true;
        },
        closeModal() {
            this.modalOpen = false;
        },
        selecionarPlano(plano) {
            if (plano === this.planoAtual) return; // Verificando se o plano é o atual

            this.planoSelecionado = plano;
        },
        salvarPlano() {
            const idDoPlano = this.planoSelecionado;
            const nomeDoPlano = `plano_${this.planoSelecionado}`;
            api
                .put(`/accounts/${idDoPlano}/${this.subs_iugu_id}/${nomeDoPlano}`)
                .then(() => {
                    this.createdMessage()
                    this.planoSelecionado = null;
                    setTimeout(() => {
                        window.location.reload();
                    }, 15000);
                })
                .catch(() => {
                    this.unsuccessfulMessage()
                });
        },
    },
};
</script >
  
  
<style>
.cartoes-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 10rem;
}

.verify-icon {
    align-items: center;
    display: flex;
    justify-content: center;
}

.cartao {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 5px;
    height: 700px;
    padding: 20px;
    transition: all 0.3s ease-in-out;
    width: 400px;
    cursor: pointer;
    align-items: center;

    outline: none !important;
}

h3 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #009732;
}

.cartao:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.cartao:hover {
    background-color: #c8e6c9;
    color: #333;
    outline: none;

}

.selecionado {
    background-color: #3ee7b763;
    outline: none !important;
}

.containerbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.salvar {
    align-self: center;
    background-color: #18ac81;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 100px;
    margin-top: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.salvar-btn {
    align-self: center;
    background-color: #18ac81;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 90px;
    margin-top: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}



.salvar:hover {
    background-color: #18ac81;
}

.salvar:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.preco {
    font-size: 1.5rem;
    font-weight: bold;
    color: #18ac81;
}

.preco small {
    font-size: 1rem;
    font-weight: bold;
    color: #18ac81;
}

.status {
    width: 100%;
    padding: 10px;
    margin-bottom: 2rem;
    color: #fff;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease-in-out;
    border-radius: 8px
}

.orange {
    background-color: rgba(255, 166, 0, 0.534);
}

.red {
    background-color: rgba(255, 0, 0, 0.534);
}

.green {
    background-color: #c8e6c9;
}

.status a {
    color: #fff;
}

.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 60%;
    max-width: 540px;
    /* Define o tamanho máximo do modal */
}

.close {
    color: #aaa;
    float: right;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.input-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.input-group {
    display: flex;
    align-items: center;
}

.input-group label {
    flex-basis: 100%;
    text-align: left;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.input-group, input {
    border-radius: 4px;
    padding: 5px;   
}


/* dispositivos móveis */
@media (max-width: 767px) {
    .cartao {
        flex-basis: 100%;
    }

    .cartoes-container {
        margin: 0 1rem;
    }

    .salvar {
        padding: 10px 100px;
    }

    .input-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;

    
}

.gridSave {
    display: flex;
    flex-direction: column;
}

}

.linha-verde {
    border-top: 2px solid #18ac81;
    width: 100%;
}

.lista-itens {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.lista-itens li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.icone-check {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #18ac8200;
    border-radius: 50%;
    margin-right: 5px;
}

.btn-selecionar {
    background-color: #18ac82;
    color: #fff;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    width: 15rem;
}
</style>
