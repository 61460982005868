<template>
  <div>
    <div class="btn-actions-content d-flex">
      <b-button
        v-if="edit"
        class="d-flex align-items-center mr-3 button-cancel"
        variant="outline-primary"
        @click="cancel()"
      >
        <VSIcons
          name="CloseSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Cancelar</span>
      </b-button>
      <b-button
        v-if="!edit"
        class="btn-grad d-flex align-items-center"
        @click="setEdit(true)"
      >
        <VSIcons
          name="Edit"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Editar</span>
      </b-button>
      <b-button
        v-else
        class="btn-grad d-flex align-items-center"
        @click="saveUser(user)"
      >
        <VSIcons
          name="TickSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Salvar</span>
      </b-button>
    </div>
    <h6 class="mb-3 mt-3">
      Permissões
    </h6>
    <validation-observer ref="form">
      <validation-provider
        v-slot="{ errors }"
        name="Permissão"
        rules="required"
      >
        <b-form-select
          v-model="user.permission"
          class="w-25"
          :options="options"
          :disabled="!edit"
          :state="errors.length > 0 ? false : null"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
      <h6 class="mb-3 mt-3">
        Dados do usuário
      </h6>

      <b-row>
        <b-col md="2">
          <validation-provider
            v-slot="{ errors }"
            name="Nome"
            rules="required|min:3"
          >
            <b-form-group label="Nome">
              <b-input-group>
                <b-form-input
                  id="nome"
                  v-model="user.full_name"
                  :disabled="!edit"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="2">
          <validation-provider
            v-slot="{ errors }"
            name="Apelido"
            rules="required|min:3"
          >
            <b-form-group label="Apelido">
              <b-input-group>
                <b-form-input
                  id="apelido"
                  v-model="user.username"
                  :disabled="!edit"
                  type="text"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="2">
          <validation-provider
            v-slot="{ errors }"
            name="e-mail"
            rules="required|email"
          >
            <b-form-group label="E-mail">
              <b-input-group>
                <b-form-input
                  id="email"
                  v-model="user.email"
                  :disabled="!edit"
                  type="email"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="2">
          <validation-provider
            v-slot="{ errors }"
            name="CPF"
            rules="required|cpf"
          >
            <b-form-group label="CPF">
              <b-input-group>
                <b-form-input
                  id="required|cpf"
                  v-model="user.cpf"
                  v-mask="'###.###.###-##'"
                  :disabled="!edit"
                  type="text"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="2">
          <validation-provider
            v-slot="{ errors }"
            name="documento de identificação"
            rules="max:100"
          >
            <b-form-group label="Documento de Identificação">
              <b-input-group>
                <b-form-input
                  id="documento-de-identificacao"
                  v-model="user.identification_document"
                  :disabled="!edit"
                  type="text"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="2">
          <validation-provider
            v-slot="{ errors }"
            name="órgão emissor"
            rules="max:100"
          >
            <b-form-group label="Órgão Emissor">
              <b-input-group>
                <b-form-input
                  id="orgao-emissor"
                  v-model="user.document_issuer"
                  :disabled="!edit"
                  type="text"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2">
          <validation-provider
            v-slot="{ errors }"
            name="Celular"
            rules="required"
          >
            <b-form-group label="Celular">
              <b-input-group>
                <b-form-input
                  id="celular"
                  v-model="user.telephone"
                  v-mask="maskTelephone(user.telephone)"
                  :disabled="!edit"
                  type="tel"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-form-checkbox v-model="user.receiveNewsletter">
        Receber e-mail de publicidade e marketing.
      </b-form-checkbox>
      <div v-if="edit">
        <h6 class="mb-3 mt-3">
          Dados de segurança do usuário
        </h6>
        <div class="password-inputs-wrapper">
          <div class="input-wrapper">
            <validation-provider
              v-slot="{ errors }"
              name="senha"
              rules="min:6|max:15|password"
            >
              <b-form-group label="Senha">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="password"
                    v-model="user.password"
                    placeholder="Senha"
                    :type="passwordFieldType"
                    :disabled="!edit"
                  />
                  <b-input-group-append
                    is-text
                    @click="togglePasswordVisibility()"
                  >
                    <VSIcons
                      :name="passwordToggleIcon"
                      color="#33383C"
                      class="cursor-pointer"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="input-wrapper">
            <validation-provider
              v-slot="{ errors }"
              name="confirmação"
              rules="validate-password:@senha"
            >
              <b-form-group label="Confirmar senha">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="confirm-password"
                    v-model="passwordConfirm"
                    placeholder="Senha"
                    :type="passwordFieldType"
                    :disabled="!edit"
                  />
                  <b-input-group-append
                    is-text
                    @click="togglePasswordVisibility()"
                  >
                    <VSIcons
                      :name="passwordToggleIcon"
                      color="#33383C"
                      class="cursor-pointer"
                    />
                  </b-input-group-append>

                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
        </div>
        <b-form-checkbox v-model="passwordGenerate">
          Gerar senha automática.
        </b-form-checkbox>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormInput, BFormCheckbox, BInputGroupAppend, BInputGroup, BFormGroup, BButton, BFormSelect, BRow, BCol,
} from 'bootstrap-vue'
import {
  required, email, min, cpf,
} from '@core/utils/validations/validations'
import { maskPhone, togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapMutations, mapGetters } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import permissions from '@/services/permissions'
import users from '@/services/users'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    VSIcons,
    BInputGroupAppend,
    BInputGroup,
    BRow,
    BCol,
  },
  mixins: [togglePasswordVisibility, maskPhone],
  data() {
    return {
      options: [],
      user: {},
      passwordConfirm: null,
      passwordGenerate: false,
      required,
      email,
      min,
      cpf,
    }
  },
  computed: {
    ...mapGetters({ edit: 'app/getItemTableEdit', itemToEdit: 'app/getItemTableDetail' }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'Eye' : 'EyeSlash'
    },
  },
  watch: {
    edit(newEdit) {
      if (newEdit) {
        this.user.password = null
        this.passwordConfirm = null
      }
    },
    passwordGenerate(newPasswordGenerate) {
      if (newPasswordGenerate) {
        this.getPassword()
      } else {
        this.user.password = null
        this.passwordConfirm = null
      }
    },
  },
  created() {
    if (this.$route.path !== '/gerenciamento-de-usuarios/criar/usuarios') {
      users.getUserById(this.$route.params.id).then(res => {
        this.user = res.data
        this.setSubTitle(res.data.email)
        this.setEdit(false)
      })
    }
    permissions.getPermissions().then(res => { this.options = res.data.map(arr => ({ value: arr, text: arr.name })) })
  },
  methods: {
    ...mapMutations({ setSubTitle: 'app/SET_SUBTITLE', setEdit: 'app/SET_ITEM_TABLE_EDIT', editItem: 'app/getItemTableEdit' }),

    getPassword() {
      const charsNumbers = '0123456789'
      const charsCapital = 'ABCDEFGHIJLMNOPQRSTUVWXYZ'
      const charsTiny = 'abcdefghijklmnopqrstuvwxyz'
      const charsSpecial = '!@#$%&*()'

      const passwordLength = 3
      let password = ''

      for (let i = 0; i < passwordLength; i += 1) {
        password += charsNumbers.charAt(Math.floor(Math.random() * (charsNumbers.length)))
        password += charsCapital.charAt(Math.floor(Math.random() * (charsCapital.length)))
        password += charsTiny.charAt(Math.floor(Math.random() * (charsTiny.length)))
        password += charsSpecial.charAt(Math.floor(Math.random() * (charsSpecial.length)))
      }
      this.user.password = password
      this.passwordConfirm = password
    },
    saveUser(user) {
      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.$route.path === '/gerenciamento-de-usuarios/criar/usuarios') {
            users.createUser(user).then(() => {
              this.toast({
                title: 'Usúario Criado',
                text: 'O usúario foi criado com sucesso.',
                variant: 'success',
              })
              this.$router.push('/gerenciamento-de-usuarios')
            })
            this.setEdit(false)
          } else {
            users.editUser(user).then(() => {
              this.toast({
                title: 'Usúario Editado',
                text: 'O usúario foi editado com sucesso.',
                variant: 'success',
              })
            })
            this.setEdit(false)
          }
        }
      })
    },
    cancel() {
      this.setEdit(false)
      this.$router.push({ name: 'user-management' })
    },
  },
}
</script>

<style lang='scss'>
.infos-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 233px);
  column-gap: 21px;
}
.password-inputs-wrapper {
display: grid;
grid-template-columns: 196px 196px;
column-gap: 21px;
}

</style>
