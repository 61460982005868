<script>
/* eslint-disable */
</script>
<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab
        title="Meus dados"
        active
      >
        <MyAccountGeneralData />
      </b-tab>
      <b-tab title="Segurança">
        <MyAccountSecurity />
      </b-tab>
      <b-tab
        v-if="getUserData.permission.name === 'Administrador' && isActive === true"
        title="Financeiro"
      >
        <MyAccountFinancial :items="accountFinancial" />
      </b-tab>
      <b-tab
        v-if="getUserData.permission.name === 'Administrador' || isActive === false"
        title="Plano"
      >
        <MyAccountPlan
          :plan="myPlan"
          :id-account="accountId"
        />
      </b-tab>
      <b-tab
        v-if="getUserData.permission.name === 'Administrador' && isActive === true"
        title="Meus tickets"
      >
        <MyTickets />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MyAccountGeneralData from './MyAccountGeneralData.vue'
import MyAccountSecurity from './MyAccountGeneralSecurity.vue'
import MyAccountFinancial from '@/components/financial-details/FinancialDetails.vue'
import MyAccountPlan from '@/components/plan-details/AccountsDetailsPlan.vue'
import MyTickets from './my-tickets/TicketsData.vue'
import useJwt from '@/auth/jwt/useJwt'
import api from '@/libs/axios'

export default {
  components: {
    BTabs,
    BTab,
    MyTickets,
    MyAccountGeneralData,
    MyAccountSecurity,
    MyAccountFinancial,
    MyAccountPlan,
  },
  data() {
    return {
      isActive: null,
    }
  },
  computed: {
    ...mapGetters({
      getUserData: 'auth/getUserData', accountFinancial: 'accounts/getMyPayment', myPlan: 'accounts/getMyPlan',
    }),
    accountId: () => useJwt.getIdMyAccount(),
  },
  created() {
    this.fetchAccountsById(this.accountId)
    this.fetchMyPlan(this.accountId)
    this.setSubTitle(this.getUserData.email)
    this.fetchMyPayment(this.accountId)
  },
  beforeDestroy() {
    this.setSubTitle('')
  },
  mounted() {
    const userIdAccount = JSON.parse(localStorage.getItem('id_account_verdesys'));
    api.get(`/accounts/${userIdAccount}`).then((response) => { 
        this.isActive = response.data.isActive;
    });
  },
  methods: {
    ...mapMutations({ setSubTitle: 'app/SET_SUBTITLE' }),
    ...mapActions({ fetchMyPayment: 'accounts/fetchMyPayment', fetchMyPlan: 'accounts/fetchMyPlan', fetchAccountsById: 'accounts/fetchAccountsById' }),
  },
}
</script>
