<template>
  <div>
    <div class="btn-actions-content d-flex">
      <b-button
        class="btn-grad d-flex align-items-center btn-action"
        @click="showModal('NewPermission')"
      >
        <VSIcons
          name="addSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span
          class="align-middle"
        >Adicionar Permissão</span>
      </b-button>

    </div>
    <div class="d-flex align-items-center justify-content-between">
      <div class="">
        <span class="text-small">Permissões</span>
        <b-form-select
          v-model="selectedPermission"
          :options="options.filter(arr => !(arr.text === 'Master' && userData.permission.name !== 'Master'))"
        />
      </div>
      <div class="grid-buttons">
        <div class="toggler-button-wrapper">
          <span class="d-block text-center title"> Todos </span>
          <div
            class="toggler-button"
            :class="statusAll ? 'active-toggler' : ''"
            @click="toggleSlideAll"
          >
            <VSIcons
              name="correct-sign"
              width="8px"
              class="fixed-icon"

              color="#fff"
            />
            <span class="fixed-icon"> - </span>
            <div
              class="slide-circle"
              :class="statusAll ? 'active' : ''"
            >
              <span>
                <VSIcons
                  v-if="statusAll"
                  name="correct-sign"
                  width="8px"
                  color="#18AC81"
                />
                <span v-else> - </span>
              </span>
            </div>
          </div>
        </div>
        <div class="toggler-button-wrapper">
          <span class="d-block text-center title"> Ver </span>
          <div
            class="toggler-button"
            :class="statusRead ? 'active-toggler' : ''"
            @click="toggleSlidePermission('read')"
          >
            <VSIcons
              name="correct-sign"
              width="8px"
              class="fixed-icon"
              color="#fff"
            />
            <span class="fixed-icon"> - </span>
            <div
              class="slide-circle"
              :class="statusRead ? 'active' : ''"
            >
              <span>
                <VSIcons
                  v-if="statusRead"
                  name="correct-sign"
                  width="8px"
                  color="#18AC81"
                />
                <span v-else> - </span>
              </span>
            </div>
          </div>
        </div>
        <div class="toggler-button-wrapper">
          <span class="d-block text-center title"> Inserir </span>
          <div
            class="toggler-button"
            :class="statusInsert ? 'active-toggler' : ''"
            @click="toggleSlidePermission('create')"
          >
            <VSIcons
              name="correct-sign"
              width="8px"
              class="fixed-icon"

              color="#fff"
            />
            <span class="fixed-icon"> - </span>
            <div
              class="slide-circle"
              :class="statusInsert ? 'active' : ''"
            >
              <span>
                <VSIcons
                  v-if="statusInsert"
                  name="correct-sign"
                  width="8px"
                  color="#18AC81"
                />
                <span v-else> - </span>
              </span>
            </div>
          </div>
        </div>
        <div class="toggler-button-wrapper">
          <span class="d-block text-center title"> Alterar </span>
          <div
            class="toggler-button"
            :class="statusChange ? 'active-toggler' : ''"
            @click="toggleSlidePermission('update')"
          >
            <VSIcons
              name="correct-sign"
              width="8px"
              class="fixed-icon"
              color="#fff"
            />
            <span class="fixed-icon"> - </span>
            <div
              class="slide-circle"
              :class="statusChange ? 'active' : ''"
            >
              <span>
                <VSIcons
                  v-if="statusChange"
                  name="correct-sign"
                  width="8px"
                  color="#18AC81"
                />
                <span v-else> - </span>
              </span>
            </div>
          </div>
        </div>
        <div class="toggler-button-wrapper">
          <span class="d-block text-center title"> Excluir </span>
          <div
            class="toggler-button"
            :class="statusDelete ? 'active-toggler' : ''"
            @click="toggleSlidePermission('delete')"
          >
            <VSIcons
              name="correct-sign"
              width="8px"
              class="fixed-icon"

              color="#fff"
            />
            <span class="fixed-icon"> - </span>
            <div
              class="slide-circle"
              :class="statusDelete ? 'active' : ''"
            >
              <span>
                <VSIcons
                  v-if="statusDelete"
                  name="correct-sign"
                  width="8px"
                  color="#18AC81"
                />
                <span v-else> - </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="permissions-details-wrapper">
      <div class="permission-items-wrapper">
        <div
          v-for="(item, index) in NavItems"
          :key="index"
          class="permission-item"
        >
          <div>
            <span class="d-block title"> {{ item.title }} </span>
          </div>
          <div class="permission-check">

            <BFormCheckbox
              v-model="selectedPermission[`${item.resource}_read`]"
              size="lg"
              :class="`read_${item.title}`"

              @change="updatePermissions"
            />
            <BFormCheckbox
              v-model="selectedPermission[`${item.resource}_create`]"
              size="lg"
              :class="`insert_${item.title}`"

              @change="updatePermissions"
            />
            <BFormCheckbox
              v-model="selectedPermission[`${item.resource}_update`]"
              size="lg"
              :class="`edit_${item.title}`"
              @change="updatePermissions"
            />
            <BFormCheckbox
              v-model="selectedPermission[`${item.resource}_delete`]"
              size="lg"
              :class="`delete_${item.title}`"
              @change="updatePermissions"
            />
          </div>
        </div>
      </div>
    </div>
    <VSModal
      name="NewPermission"
      :properties="userData.username"
    />
  </div>
</template>
<script>
import { BButton, BFormCheckbox, BFormSelect } from 'bootstrap-vue'
import { mapMutations, mapGetters } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'
import permissions from '@/services/permissions'
import NavItems from '@/components/navigation/nav-itens'

export default {
  components: {

    BButton,
    VSIcons,
    VSModal,
    BFormCheckbox,
    BFormSelect,

  },
  data() {
    return {
      options: [],
      NavItems,
      status: false,
      statusRead: false,
      statusInsert: false,
      statusChange: false,
      statusDelete: false,
      statusAll: false,
      selectedPermission: {},
    }
  },
  computed: {
    ...mapGetters({ userData: 'auth/getUserData' }),
  },
  watch: {
    selectedPermission() {
      this.slideToggleCreatedIfAllSelected()
    },
  },
  created() {
    this.setSubTitle('Permissão')
    permissions.getPermissions().then(res => {
      this.options = res.data.map(arr => ({ text: arr.name, value: arr }))
      this.selectedPermission = this.options[0].value
      this.slideToggleCreatedIfAllSelected()
    })
  },
  methods: {
    ...mapMutations({ setSubTitle: 'app/SET_SUBTITLE' }),
    slideToggleCreatedIfAllSelected() {
      this.statusRead = !Object.entries(this.selectedPermission).filter(([key]) => key.includes('_read')).some(arr => arr[1] === false)
      this.statusInsert = !Object.entries(this.selectedPermission).filter(([key]) => key.includes('_create')).some(arr => arr[1] === false)
      this.statusChange = !Object.entries(this.selectedPermission).filter(([key]) => key.includes('_update')).some(arr => arr[1] === false)
      this.statusDelete = !Object.entries(this.selectedPermission).filter(([key]) => key.includes('_delete')).some(arr => arr[1] === false)
      if (this.statusRead && this.statusInsert && this.statusChange && this.statusDelete) {
        this.statusAll = true
      } else {
        this.statusAll = false
      }
    },
    toggleSlidePermission(type) {
      if (type === 'read') this.statusRead = !this.statusRead
      if (type === 'create') this.statusInsert = !this.statusInsert
      if (type === 'update') this.statusChange = !this.statusChange
      if (type === 'delete') this.statusDelete = !this.statusDelete

      const readArr = Object.fromEntries(Object.entries(this.selectedPermission).filter(([key]) => key.includes(type)))

      Object.keys(readArr).forEach(key => {
        // eslint-disable-next-line no-nested-ternary
        readArr[key] = type === 'read' ? this.statusRead : type === 'create' ? this.statusInsert : type === 'update' ? this.statusChange : this.statusDelete
        this.selectedPermission = Object.assign(this.selectedPermission, readArr)
      })

      this.updatePermissions()
    },
    toggleSlideAll() {
      this.statusAll = !this.statusAll
      if (this.statusAll) {
        if (!this.statusRead) {
          this.statusRead = true
        }
        if (!this.statusChange) {
          this.statusChange = true
        }
        if (!this.statusDelete) {
          this.statusDelete = true
        }
        if (!this.statusInsert) {
          this.statusInsert = true
        }
      }
      if (this.statusAll) {
        const allObj = Object.fromEntries(Object.entries(this.selectedPermission).filter(([key]) => key.includes('_')))
        Object.keys(allObj).forEach(key => {
          allObj[key] = true
        })
        this.selectedPermission = Object.assign(this.selectedPermission, allObj)
      } else {
        const allObj = Object.fromEntries(Object.entries(this.selectedPermission).filter(([key]) => key.includes('_')))
        Object.keys(allObj).forEach(key => {
          allObj[key] = false
        })
        this.selectedPermission = Object.assign(this.selectedPermission, allObj)
      }

      this.updatePermissions()
    },
    updatePermissions() {
      delete this.selectedPermission.updatedAt
      delete this.selectedPermission.createdAt
      delete this.selectedPermission.deletedAt
      permissions.updatePermission(this.selectedPermission).then(() => {
        this.slideToggleCreatedIfAllSelected()
        this.toast({
          title: 'Permissão editada',
          text: 'Permissão editada com sucesso',
          variant: 'success',

        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.text-small {
font-weight: 600;
font-size: 14px;
line-height: 13px;
letter-spacing: 0.75px;
color: #33383C;
}
.grid-buttons {
  display: grid;
  grid-template-columns: repeat(5, 100px);
}
.btn-action {
  min-width: 200px;
  white-space: nowrap;
}

.permission-items-wrapper {
  margin-top: 22px;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);
  border-radius: 13px;
  max-height: 70vh;
  overflow: scroll;
  padding-top: 2rem;
  padding-left: 34px;
  .permission-item {
    height: 100px;
    display: flex;
    justify-content: space-between;
    .title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #33383C;
    }
    .description {
      font-size: 13px;
      letter-spacing: 0.75px;
      color: #7D8E9E;
    }
  }
  .permission-check {
    display: grid;
    grid-template-columns: repeat(4, 100px);
    justify-content: center;
    justify-items: center;
  }
}

.toggler-button-wrapper {
  cursor: pointer;
  width: 100px;
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.75px;
      color: #7D8E9E;
      margin-bottom: 13px;
    }
    .toggler-button {
    transition: .3s ease-in-out all;
    width: 69px;
    height: 35px;
    background: #A0AEB9;
    border-radius: 100px;
    margin: auto;
    display: block;
    position: relative;
      .slide-circle {
      transition: .3s ease-in-out all;
      cursor: pointer;
      padding: 10px;
      background: #FFFFFF;
      border-radius: 100px;
      width: 21px;
      height: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      margin: auto;
      top: 0;
      left: 5px;
      transform: translateX(0%);
          }
      }
}
.active {
  transform: translateX(155%)!important;
  transition: .3s ease-in-out all;
}
.active-toggler {
  background: #18AC81!important;
}
.fixed-icon {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: translateY(5px) translateX(-5px);
  &:nth-child(2) {
    right: initial;
    left: 5px;
    transform: translateY(5px) translateX(5px);
    color: #fff;
  }
}
</style>
