<template>
  <ImportLicenseTable />
</template>

<script>
import ImportLicenseTable from '@/modules/file-import/FileImportLicenseTable.vue'

export default {
  components: { ImportLicenseTable },
}
</script>
