var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"btn-actions-content d-flex"},[(_vm.edit)?_c('b-button',{staticClass:"d-flex align-items-center mr-3 button-cancel",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.cancel()}}},[_c('VSIcons',{staticClass:"pr-2",attrs:{"name":"CloseSquare","width":"22","height":"22"}}),_c('span',[_vm._v("Cancelar")])],1):_vm._e(),(!_vm.edit)?_c('b-button',{staticClass:"btn-grad d-flex align-items-center",on:{"click":function($event){return _vm.setEdit(true)}}},[_c('VSIcons',{staticClass:"pr-2",attrs:{"name":"Edit","width":"22","height":"22"}}),_c('span',[_vm._v("Editar")])],1):_c('b-button',{staticClass:"btn-grad d-flex align-items-center",on:{"click":function($event){return _vm.saveUser(_vm.user)}}},[_c('VSIcons',{staticClass:"pr-2",attrs:{"name":"TickSquare","width":"22","height":"22"}}),_c('span',[_vm._v("Salvar")])],1)],1),_c('h6',{staticClass:"mb-3 mt-3"},[_vm._v(" Permissões ")]),_c('validation-observer',{ref:"form"},[_c('validation-provider',{attrs:{"name":"Permissão","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"w-25",attrs:{"options":_vm.options,"disabled":!_vm.edit,"state":errors.length > 0 ? false : null},model:{value:(_vm.user.permission),callback:function ($$v) {_vm.$set(_vm.user, "permission", $$v)},expression:"user.permission"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('h6',{staticClass:"mb-3 mt-3"},[_vm._v(" Dados do usuário ")]),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"nome","disabled":!_vm.edit,"state":errors.length > 0 ? false : null,"type":"text"},model:{value:(_vm.user.full_name),callback:function ($$v) {_vm.$set(_vm.user, "full_name", $$v)},expression:"user.full_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Apelido","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Apelido"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"apelido","disabled":!_vm.edit,"type":"text"},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"E-mail"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"email","disabled":!_vm.edit,"type":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"CPF","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CPF"}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"id":"required|cpf","disabled":!_vm.edit,"type":"text"},model:{value:(_vm.user.cpf),callback:function ($$v) {_vm.$set(_vm.user, "cpf", $$v)},expression:"user.cpf"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"documento de identificação","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Documento de Identificação"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"documento-de-identificacao","disabled":!_vm.edit,"type":"text"},model:{value:(_vm.user.identification_document),callback:function ($$v) {_vm.$set(_vm.user, "identification_document", $$v)},expression:"user.identification_document"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"órgão emissor","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Órgão Emissor"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"orgao-emissor","disabled":!_vm.edit,"type":"text"},model:{value:(_vm.user.document_issuer),callback:function ($$v) {_vm.$set(_vm.user, "document_issuer", $$v)},expression:"user.document_issuer"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Celular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Celular"}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.maskTelephone(_vm.user.telephone)),expression:"maskTelephone(user.telephone)"}],attrs:{"id":"celular","disabled":!_vm.edit,"type":"tel"},model:{value:(_vm.user.telephone),callback:function ($$v) {_vm.$set(_vm.user, "telephone", $$v)},expression:"user.telephone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-form-checkbox',{model:{value:(_vm.user.receiveNewsletter),callback:function ($$v) {_vm.$set(_vm.user, "receiveNewsletter", $$v)},expression:"user.receiveNewsletter"}},[_vm._v(" Receber e-mail de publicidade e marketing. ")]),(_vm.edit)?_c('div',[_c('h6',{staticClass:"mb-3 mt-3"},[_vm._v(" Dados de segurança do usuário ")]),_c('div',{staticClass:"password-inputs-wrapper"},[_c('div',{staticClass:"input-wrapper"},[_c('validation-provider',{attrs:{"name":"senha","rules":"min:6|max:15|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Senha"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"password","placeholder":"Senha","type":_vm.passwordFieldType,"disabled":!_vm.edit},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('b-input-group-append',{attrs:{"is-text":""},on:{"click":function($event){return _vm.togglePasswordVisibility()}}},[_c('VSIcons',{staticClass:"cursor-pointer",attrs:{"name":_vm.passwordToggleIcon,"color":"#33383C"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,142351821)})],1),_c('div',{staticClass:"input-wrapper"},[_c('validation-provider',{attrs:{"name":"confirmação","rules":"validate-password:@senha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Confirmar senha"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"confirm-password","placeholder":"Senha","type":_vm.passwordFieldType,"disabled":!_vm.edit},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}}),_c('b-input-group-append',{attrs:{"is-text":""},on:{"click":function($event){return _vm.togglePasswordVisibility()}}},[_c('VSIcons',{staticClass:"cursor-pointer",attrs:{"name":_vm.passwordToggleIcon,"color":"#33383C"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3182787914)})],1)]),_c('b-form-checkbox',{model:{value:(_vm.passwordGenerate),callback:function ($$v) {_vm.passwordGenerate=$$v},expression:"passwordGenerate"}},[_vm._v(" Gerar senha automática. ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }