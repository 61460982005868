<template>
  <div>
    <div class="btn-actions-content d-flex">
      <b-button
        v-if="edit"
        class="d-flex align-items-center mr-3 button-cancel"
        variant="outline-primary"
        @click="setEdit(false)"
      >
        <VSIcons
          name="CloseSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Cancelar</span>
      </b-button>
      <b-button
        v-if="!edit"
        class="btn-grad d-flex align-items-center"
        @click="setEdit(true)"
      >
        <VSIcons
          name="Edit"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Editar</span>
      </b-button>
      <b-button
        v-else
        class="btn-grad d-flex align-items-center"
        @click="saveChanges()"
      >
        <VSIcons
          name="TickSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Salvar</span>
      </b-button>
    </div>
    <h6 class="mt-4">
      Senha de acesso
    </h6>
    <b-row>
      <b-col
        class="mt-2"
        md="5"
      >
        <validation-observer ref="form">
          <b-row>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="senha"
                rules="required|min:6|max:15|password"
              >
                <b-form-group label="Senha">
                  <b-input-group class="input-group-merge disabled">
                    <b-form-input
                      id="password"
                      v-model="newPassord"
                      placeholder="Senha"
                      type="password"
                      :disabled="!edit"
                    />
                    <b-input-group-append
                      v-if="!edit"
                      v-b-popover="{ variant: 'dark', content: 'Este campo está desabilitado para alterações.' }"
                      is-text
                      class="cursor-pointer"
                    >
                      <VSIcons
                        name="InfoCircle"
                        color="#A0AEB9"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col class="pl-0">
              <validation-provider
                v-slot="{ errors }"
                name="confirmação"
                rules="validate-password:@senha"
              >
                <b-form-group label="Confirmar Senha">
                  <b-input-group
                    :disabled="!edit"
                    class="input-group-merge disabled"
                  >
                    <b-form-input
                      id="confirm-password"
                      v-model="newPassordConfirm"
                      placeholder="Senha"
                      type="password"
                      :disabled="!edit"
                    />
                    <b-input-group-append
                      v-if="!edit"
                      v-b-popover="{ variant: 'dark', content: 'Este campo está desabilitado para alterações.' }"
                      is-text
                      class="cursor-pointer"
                    >
                      <VSIcons
                        name="InfoCircle"
                        color="#A0AEB9"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
        <h6 class="mt-4">
          Configuração de segurança extra
        </h6>
        <b-row>
          <b-col>
            <b-form-group>
              <b-input-group>
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  Receber alerta sobre logins não reconhecidos.
                </b-form-checkbox>
              </b-input-group>
            </b-form-group>
            <p class="text-info-myaccount">
              Quando sua conta estiver sendo acessada de um outro
              dispositivo, você será notificado via e-mail e por sms.
            </p>
          </b-col>
        </b-row>

        <h6 class="mt-4">
          Onde você fez login
        </h6>
        <div class="acessed-device">
          <b-card
            v-for="device in devicesLogged"
            :key="device.id"
            class="acessed-device-card"
          >
            <div class="d-flex align-items-center">
              <VSIcons
                :name="device.device === 'Computador' ? 'Monitor' : 'Mobile'"
                color="#7D8E9E"
                class="acessed-device-icon"
              />
              <div class="mr-auto">
                <h6 class="acessed-device-info">
                  {{ device.device }} com {{ device.system }}
                </h6>
                <div class="d-flex">
                  <div class="acessed-device-city j-c-center">
                    {{ device.address }}
                  </div>
                  <div class="acessed-device-date">
                    Há {{ getLoginTime(Math.floor(device.timeSinceLoginInHours * 60 )) }}
                  </div>
                </div>
              </div>
              <b-dropdown
                no-caret
                right
                variant="light"
                menu-class="dropdown-table-actions"
                toggle-class="button-dropdown-actions"
              >
                <template #button-content>
                  <VSIcons
                    name="Actions"
                    width="5"
                    height="22"
                  />
                </template>
                <b-dropdown-item class="dropdown-table-item">
                  <VSIcons
                    name="Warning2"
                    width="20"
                    height="20"
                    color="#7D8E9E"
                  />
                  <span>
                    Não foi você ?
                  </span>
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item class="dropdown-table-item">
                  <VSIcons
                    name="Logout"
                    width="21"
                    height="21"
                    color="#7D8E9E"
                  />
                  <span>
                    Desconectar
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-card>
        </div>
      </b-col>

      <!-- mapa -->
      <!-- <b-col md="7">
        <GoogleMap />

      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormCheckbox,
  BCard,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BButton,
  VBPopover,
} from 'bootstrap-vue'
import { mapGetters, mapMutations } from 'vuex'
import { min } from '@core/utils/validations/validations'
import VSIcons from '@/assets/icons/VSIcons.vue'
import users from '@/services/users'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormCheckbox,
    BCard,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    VSIcons,

  },
  directives: {
    'b-popover': VBPopover,
  },
  data() {
    return {
      min,
      newPassord: '',
      newPassordConfirm: '',
      mapSettings: [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
          ],
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161',
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#f5f5f5',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#bdbdbd',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dadada',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5',
            },
          ],
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#c9c9c9',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
      ],
      devicesLogged: [],
    }
  },
  computed: {
    ...mapGetters({ edit: 'app/getItemTableEdit', itemToEdit: 'app/getItemTableDetail' }),
  },
  async created() {
    const userId = JSON.parse(localStorage.getItem('user')).id
    const { data } = await users.getUserLogin(userId)
    this.devicesLogged = data
  },
  methods: {
    ...mapMutations({ setEdit: 'app/SET_ITEM_TABLE_EDIT' }),
    saveChanges() {
      this.$refs.form.validate().then(success => {
        if (success) {
          users.editUser({ id: JSON.parse(localStorage.getItem('user')).id, password: this.newPassord }).then(() => {
            this.setEdit(false)
            this.toast({
              title: 'Senha atualizada',
              text: 'A senha foi atualizada com sucesso',
              variant: 'success',
            })
          })
        }
      })
    },
    // eslint-disable-next-line no-unused-vars
    getLoginTime(payload) {
      const loginInMinutes = payload
      const day = 1140
      const week = 10080
      const month = 43800
      const year = 525601

      if (loginInMinutes <= 59) {
        return `${loginInMinutes} minutos`
      }
      if (loginInMinutes >= 60 && loginInMinutes < day) {
        if (loginInMinutes / 60 >= 2) {
          return `${Math.floor(loginInMinutes / 60)} horas`
        }
        return `${Math.floor(loginInMinutes / 60)} hora`
      }
      if (loginInMinutes >= day && loginInMinutes <= week) {
        if (Math.floor(loginInMinutes / day) >= 2) {
          return `${Math.floor(loginInMinutes / day)} dias`
        }
        return `${Math.floor(loginInMinutes / day)} dia`
      }
      if (loginInMinutes >= week && loginInMinutes < month) {
        const weeks = Math.floor(loginInMinutes / week)
        if (weeks >= 2) {
          return `${weeks} semanas`
        }
        return `${weeks} semana`
      }
      if (loginInMinutes >= month && loginInMinutes < year) {
        const months = Math.floor(loginInMinutes / month)
        if (months >= 2) {
          return `${months} meses`
        }
        return `${months} mês`
      }
      if (loginInMinutes >= year) {
        if (Math.floor(loginInMinutes / year) >= 2) {
          return `${Math.floor(loginInMinutes / year)} anos`
        }
        return `${Math.floor(loginInMinutes / year)} ano`
      }
      return loginInMinutes
    },
  },
}
</script>

<style lang="scss" scoped>

.text-info-myaccount {
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: #7D8E9E;
}

.acessed-device {
  height: 41vh;
  overflow: auto;
  .acessed-device-card{
    margin: 0 1rem 1rem 0;
    border: 0;
    border-radius: 9px;
    .acessed-device-icon {
      height: 55px;
      width: 55px;
      border-radius: 50%;
      background-color: rgba(160, 174, 185, 0.21);
      margin: 0 10px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .acessed-device-city {
      background: linear-gradient(90deg, #076E5F 0%, #18AC81 100%);
      border-radius: 5px;
      width: 157px;
      height: 26px;

      font-weight: 500;
      font-size: 13px;
      line-height: 16px;

      text-align: center;

      color: #FFFFFF;
    }
    .acessed-device-date {
      margin-left: 10px;
      font-size: 14px;
      color: #7D8E9E;
      display: flex;
      align-items: center;
    }
    .acessed-device-button {
      background-color: transparent;
      border: 2px solid rgba(160, 174, 185, 0.21);
      box-sizing: border-box;
      border-radius: 9px;
      transform: rotate(-180deg);
    }
  }
}
</style>
