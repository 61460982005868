<script>
/* eslint-disable */
</script>
<template>
  <div>
    <div
      v-for="(category, index) in categories"
      :key="index"
      class="cardHelp mt-2"
    >
      <button
        class="btn btn-secondary"
        type="button"
        @click="toggleCollapse(category)"
      >
        <div class="d-inline-block">
          {{ category.title }}
        </div>
        <div class="d-inline-block ml-2">
          <VSIcons
            name="ArrowDownHeader"
            color="#FFFFFF"
            :class="{ 'rotateIcon': category.isCollapsed }"
          />
        </div>
      </button>
      <div
        :class="['collapse', 'mt-2', { show: category.isCollapsed }]"
      >
        <div class="card card-body text-justify">
          <div 
            v-for="(topic, index) in category.topics"
            :key="index"
          >
            <br/>
            <p class="text-info-help">{{ topic.text }}</p>
            
            <div 
              v-for="(img, index) in topic.imgs"
              :key="index"
              class="row"
            >
              <div class="col-12">
                <br/>
                <img class="img-help" :src="require('../../assets/images/help-imgs/' + img)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .rotateIcon {
    transform: rotate(180deg);
  }

  .text-info-help {
    font-size: 16px;
    color: #33383C;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.75px;
  }

  .img-help {
    width: 100%;
    height: auto;
  }
</style>

<script>
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    VSIcons,
  },
  data() {
    return {
      categories: [
      {
          title: 'Dashboard',
          isCollapsed: false,
          topics: [
            {
              text: `No quadro “Status” será possível visualizar indicadores quantitativos em relação aos cadastros realizados.`,
              imgs: [
                "dash-01.jpg",
              ]
            }, 
            {
              text: `Na segunda pagina do “Status” será possível visualizar indicadores quantitativos em relação aos processos de licenciamento e licenças cadastradas, divididos por etapas.`,
              imgs: [
                "dash-02.jpg",
              ]
            },
            {
              text: `Neste quadro será possível analisar a produtividade de cada usuário/gestor principal tanto em relação a situação dos processos de licenciamento quanto das licenças.`,
              imgs: [
                "dash-03.jpg",
              ]
            },
            {

              text: `No calendário de documentos será possível identificar o dia de vencimento de cada documento dentro dos processos de licenciamento. Caso haja um documento vencido ou à vencer o dia especifico fica marcado em vermelho para uma identificação imediata.`,
              imgs: [
                "dash-04.jpg",
              ]
            },
          ]
        },
        {
          title: 'Menu Lateral - Perfil',
          isCollapsed: false,
          topics: [
            {
              text: `O menu lateral, no canto esquerdo, serve para navegação entre os módulos do sistema.
              Por padrão o menu fica oculto, sendo mostrado apenas os ícones, porém, ao passar os cursor do mouse sobre o menu ele é expandido automaticamente, permitindo também a visualização dos nomes de cada módulo.
              O menu de configuração das contas e suporte fica oculto e pode ser visualizado ao clicar na seta ao lado no nome do usuário.
              Em minha conta é permitido alterar os dados cadastrais do usuário, alterar de senha, bem como a visualização do detalhamento da conta.
              Em “gerenciar usuários” é permitido adicionar/editar/excluir usuários bem como gerenciar as permissões de acesso.`,
              imgs: [
                "menu.png",
              ],
            },
          ]
        },
        {
          title: 'Etapa 1 – Cadastro das Empresas',
          isCollapsed: false,
          topics: [
            {
              text: `A “Empresa” pode ser uma matriz, holding, grupo, um gestor ou outro equivalente, e tem como objetivo principal agrupar os empreendimentos. É permitido cadastrar tanto pessoa física como pessoa jurídica.`,
              imgs: [
                "step1-01.png",
              ],
            },
            {
              text: `Para criar uma nova empresa é preciso clicar no botão adicionar no canto superior direito da tela. Em seguida o usuário deve optar entre pessoa física ou jurídica e preencher os campos, sendo obrigatório, no mínimo, o CNPJ, Nome Fantasia, Razão Social e Setor.`,
              imgs: [
                "step1-02.png",
              ],
            },
            {
              text: `Após a criação da empresa, é possível ainda editar os dados, caso necessário. Existe também um menu superior com quatro abas: Dados Gerais, Empreendimento, Contato e Anexo. Na aba empreendimento, será mostrada uma lista com todos os empreendimentos vinculados a esta empresa, bem como a quantidade de processo de licenciamento e licenças existentes.`,
              imgs: [
                "step1-03.png",
              ],
            },
            {
              text: `Na aba contato é possível cadastrar dados como: nome, e-mail, telefone, cargo, data de aniversário, dentre outros.Na aba anexos  é possível anexar arquivos de qualquer natureza, nos formatos pdf, xsls, docx, png ou jpeg.`,
              imgs: [],
            },
          ]
        },
        {
          title: 'Etapa 2 – Cadastro dos Empreendimentos',
          isCollapsed: false,
          topics: [
            {
              text: `O “Empreendimento” é o agente a ser licenciado, bem como o que obterá a respectiva licença e terá os resíduos geridos. Todo empreendimento deve, obrigatoriamente, estar vinculado uma “Empresa”. É permitido cadastrar tanto pessoa física como pessoa jurídica.`,
              imgs: [
                "step2-01.png",
              ],
            },
            {
              text: `Para criar um novo empreendimento é preciso clicar no botão adicionar no canto superior direito da tela. Em seguida o usuário deve optar entre pessoa física ou jurídica e preencher os campos, sendo obrigatório, no mínimo, o CNPJ, Nome Fantasia, Razão Social, Setor e Empresa Vinculada.`,
              imgs: [
                "step2-02.png",
              ],
            },
            {
              text: `Após a criação do empreendimento é possível ainda editar os dados, caso necessário. Existe também um menu superior com cinco abas: Dados Gerais, Licenciamentos, Licenças, Contato e Anexo. Na aba licenciamentos será mostrada uma lista com todos os processos de licenciamento vinculados a este empreendimento, bem como o tipo de licença, o órgão licenciador e a quantidade de documentos cadastrados em cada processo. Na aba licenças será mostrada uma lista com todas as licenças vinculadas a este empreendimento, bem como o tipo de licença, o órgão licenciador e a quantidade de condicionantes cadastrados em cada licença.`,
              imgs: [
                "step2-03.png",
              ],
            },
            {
              text: `Na aba contato é possível cadastrar dados como: nome, e-mail, telefone, cargo, data de aniversário, dentre outros.Na aba anexos  é possível anexar arquivos de qualquer natureza, nos formatos pdf, xsls, docx, png ou jpeg.`,
              imgs: [],
            },
          ]
        },
        {
          title: 'Etapa 3 – Cadastro de Órgão Licenciador, Tipos de Licença e Fornecedores',
          isCollapsed: false,
          topics: [
            {
              text: `No cadastro de “órgãos licenciadores” é necessário, no mínimo, o preenchimento do Nome, Sigla e a Competência do respectivo órgão.`,
              imgs: [
                "step3-01.jpg",
              ],
            },
            {
              text: `Após a criação do órgão licenciador é possível ainda editar os dados, caso necessário. Existe também um menu superior com quatro abas: Dados Gerais, Tipos de Licença, Contato e Anexo. Na aba tipos de licença será mostrada uma lista com todas as modalidades de licenças vinculadas a este órgão licenciador.`,
              imgs: [
                "step3-02.jpg",
              ],
            },
            {
              text: `No cadastro de “tipos de licenças” é necessário o preenchimento dos campos  título, Sigla, Descrição e a Órgão Licenciador vinculado.`,
              imgs: [
                "step3-03.jpg",
              ],
            },
            {
              text: `No cadastro de “fornecedor” é necessário, no mínimo, o preenchimento do CNPJ/CPF, Nome Fantasia, Razão Social e o Serviço. Este fornecedor poderá ser utilizado para identificar o executor de um documento na gestão do licenciamento, de uma condicionantes da licença ou de um serviço no gerenciamento de resíduos.`,
              imgs: [
                "step3-04.jpg",
              ],
            },
            {
              text: `Após a criação do órgão licenciador é possível ainda editar os dados, caso necessário. Existe também um menu superior com três abas: Dados Gerais, Contato e Anexo.`,
              imgs: [],
            },
          ]
        },
        {
          title: 'Etapa 4 – Gestão de Processos de Licenciamento Ambiental',
          isCollapsed: false,
          topics: [
            {
              text: `Neste módulo o usuário poderá fazer a gestão dos processos de licenciamento de qualquer natureza, desde que os cadastros básicos (empresa, empreendimento, órgão licenciador e tipos de licença) tenham sido criados previamente. Os processos já cadastrados podem ser visualizados de duas formas: formato de lista e formato em kanban. Em ambos os formatos as informações mais importantes serão visualizadas. No caso do formato lista, além dos dados básicos para identificação do processo, também será visualizado o prazo regressivo (prazo restante para atingir a data limite estabelecida para os projetos) e o progresso de cada projeto (considerando a quantidade de documentos concluídos em relação a quantidade cadastrada). Para acessar o detalhamento de qualquer processo basta um clique sobre qualquer coluna do mesmo.`,
              imgs: [
                "step4-01.jpg",
              ]
            },
            {
              text: `No formato Kanban os processos estarão dispostos de acordo com a fase atribuída para cada processo, sendo: INSTRUÇÃO, PROTOCOLO, EM ANÁLISE E CONCLUÍDO. Para melhor otimização, as informações ficam em modo retraído. Para expandir basta clicar em qualquer área da caixa. Para acessar o detalhamento de qualquer processo basta um clique sobre ícone (verde) no canto inferior direito de cada caixa.`,
              imgs: [
                "step4-02.jpg",
                "step4-03.jpg",
              ]
            },
            {
              text: `Para criar um novo processo de Licenciamento é preciso clicar no botão “adicionar” no canto superior direito da tela. Em seguida o usuário deve selecionar um dos empreendimentos cadastros previamente e atribuir um gestor principal para o processo. Além destes campos, é também obrigatório o preenchimento do: Código e Nome do Projeto/Processo (ambos de livre preenchimento à critério do usuário, recomendamos uma nomenclatura padronizada que facilite uma rápida identificação do mesmo), órgão licenciador, tipo de processo, o tipo da licença (conforme cadastro prévio), fase (instrução, protocolo, em análise e concluído) e a data de início da gestão do processo. Os demais campos não são de preenchimento obrigatório. Para o adequado gerenciamento de prazo do processo, é possível atribuir uma data de conclusão ou um prazo de conclusão (em dias), e o sistema mostrará uma contagem regressiva até a data selecionada.`,
              imgs: [
                "step4-04.jpg",
                "step4-05.jpg",
                "step4-06.jpg",
              ]
            },
            {
              text: `Após a criação de um processo de licenciamento, vocês estará pronto para gestão do mesmo. O ambiente de cada licenciamento possui sete ambas, que permitirão ao usuário a gestão completa de todo o processo, até a obtenção da licença. Na aba “Dados Gerais”,  o usuário pode alterar os campos a qualquer momento, clicando no botão editar. Na aba “Documentos e atividades” o usuário poderá gerir todos os documentos necessários a instrução do processo, bem como designar um responsável, acompanhar os prazos e classificar por status. Também é possível atribuir a data em que o respectivo documento foi protocolado junto ao órgão licenciador. A aba “Diário de Bordo” é um espaço onde o usuário poderá registar todos os acontecimentos do processo, tais como reuniões, visitas técnicas, audiências, contatos telefônicos, ou seja, um ambiente para registro dos acontecimentos mais importantes durante o curso do processo de licenciamento. Na aba “Notificações” é permitido cadastrar notificações, autos de constatação e autos de infração, que tenham exigências e prazos a serem cumpridos. Na aba “Pareceres e Vistorias” o usuário poderá cadastrar a relação desses documentos, bem como anexar o respectivo. Na aba “Responsáveis” o usuário poderá cadastrar os responsáveis legais, técnicos, procuradores, consultores, dentre outros. Na aba “Anexo” será possível visualizar todos os documentos anexados em todas as abas. Em “Documentos e Atividades” basta clicar no botão adicionar para a criação de um novo documento ou atividade, bem como é possível selecionar um documento já criado para editar os dados existentes. Na tela de inicial será mostrado uma lista com todos os documentos criados, bem como as informações mais importantes. Para cada documentos é possível incluir anotações e acompanhar o prazo restante para cumprimento daquela tarefa`,
              imgs: [
                "step4-07.jpg"
              ]
            },
            {
              text: `Para criação de um novo documento ou atividade é obrigatório o preenchimento  dos campos status, título e descrição. Também é possível atribuir data de inicio e data ou prazo de conclusão, que o sistema calculará a contagem regressiva. Caso o usuário tenha feito previamente o cadastro de uma notificação ou de um fornecedor, ele poderá realizar a devida seleção.`,
              imgs: [
                "step4-08.jpg"
              ]
            },
            {
              text: `Para criação de uma notificação deverão ser preenchidos, obrigatoriamente, os campos: Número da notificação, Órgão emissor, Data de Emissão e Data de Conclusão. Os demais campos são opcionais.`,
              imgs: [
                "step4-09.jpg"
              ]
            },
            {
              text: `Para criação de um parecer deverão ser preenchidos, obrigatoriamente, os campos: número, título, órgão emissor e objeto do parecer. Os demais campos são opcionais.`,
              imgs: [
                "step4-10.jpg"
              ]
            },
          ]
        },
        {
          title: 'Etapa 5 – Gestão de Licenças Ambientais e Condicionantes',
          isCollapsed: false,
          topics: [
            {
              text: `Neste módulo o usuário poderá fazer a gestão completa das Licenças Ambientais obtidas e suas respectivas condicionantes. Os licenças cadastrados podem ser visualizados de duas formas: formato de lista e formato em kanban. Em ambos os formatos as informações mais importantes serão visualizadas. No caso do formato lista, além dos dados básicos para identificação da licença, também serão visualizados a sigla do órgão emissão, o tipo de licença, a data de sua validade, além de um alerta que indicará o prazo limite para que seja requerida a renovação ou prorrogação da licença. Para acessar o detalhamento de qualquer licença basta um clique sobre qualquer campo.`,
              imgs: [
                "step5-01.jpg"
              ]
            },
            {
              text: `No formato Kanban as licenças estarão dispostos de acordo com a situação, podendo ser: Vigente, Vencida, Renovação dentro ou fora do prazo e Prorrogação dentro ou fora do prazo. Para melhor otimização, as informações ficam em modo retraído. Para expandir basta clicar em qualquer área da caixa. Para acessar o detalhamento de qualquer licença basta um clique sobre ícone (verde) no canto inferior direito de cada caixa.`,
              imgs: [
                "step5-02.jpg",
                "step5-03.jpg",
              ]
            },
            {
              text: `Para cadastrar uma nova Licença é preciso clicar no botão “adicionar” no canto superior direito da tela. Em seguida o usuário deve atribuir um Código e um Nome para a Licença (ambos os campos são de livre preenchimento, à critério do usuário, em que recomendamos uma nomenclatura padronizada para uma rápida identificação da licença), selecionar um dos empreendimentos previamente cadastros e atribuir um gestor principal. Além destes campos, é também obrigatório o preenchimento do órgão licenciador, do tipo de processo, do tipo da licença, do numero do processo, do numero da licença, da situação em que a licença se encontra e a data de início da gestão da licença. Os demais campos não são de preenchimento obrigatório.Para o adequado gerenciamento de prazo do processo, é possível atribuir uma data de conclusão ou um prazo de conclusão (em dias), e o sistema mostrará uma contagem regressiva até a data selecionada.É possível também determinar em que antecedência a licença deve ser renovada (em dias) e a antecedência (em dias) que o alerta de renovação deve ser acionado.`,
              imgs: [
                "step5-04.jpg",
                "step5-05.jpg",
              ]
            },
            {
              text: `Após a criação de uma Licença, vocês estará pronto para iniciar o gerenciamento. O ambiente de cada licença possui sete ambas, que permitirão ao usuário uma gestão completa durante toda a sua vigência. Na aba “Dados Gerais”,  o usuário pode alterar os campos a qualquer momento, clicando no botão editar. Na aba “Condicionantes e atividades” o usuário poderá gerir todas as condicionantes listadas do corpo da licença, bem como designar um responsável pela execução de cada uma delas, acompanhar os prazos, atribuir uma recorrência, classificar por status e atribuir a data em que o respectivo documento, em cumprimento à condicionante, foi protocolado junto ao órgão emissor. A aba “Diário de Bordo” é um espaço onde o usuário poderá registar todos os acontecimentos durante a vigência, tais como reuniões, visitas técnicas, audiências, contatos telefônicos. Na aba “Notificações” é permitido cadastrar notificações, autos de constatação e autos de infração, que tenham exigências e prazos a serem cumpridos. Na aba “Pareceres e Vistorias” o usuário poderá cadastrar e anexar esses documentos. Na aba “Responsáveis” o usuário poderá cadastrar os responsáveis legais, técnicos, procuradores, consultores, dentre outros. Na aba “Anexo” será possível visualizar todos os documentos anexados em todas as abas.`,
              imgs: []
            },
            {
              text: `Em “Condicionantes e Atividades” basta clicar no botão adicionar para a criação de um novo registro. Também é possível editar uma condicionante existente, clicando sobre o três pontinhos da ultima coluna de cada linha. Para cada condicionante é possível incluir anotações e acompanhar o prazo restante para cumprimento daquela tarefa.`,
              imgs: [
                "step5-06.jpg",
              ]
            },
            {
              text: `Para criação de uma nova condicionante é obrigatório o preenchimento  dos campos status, título e descrição. Também é possível atribuir data de inicio e data ou prazo de conclusão, que o sistema mostrará a contagem regressiva. Caso o usuário tenha feito previamente o cadastro de uma notificação ou de um fornecedor, ele poderá realizar a devida seleção.`,
              imgs: [
                "step5-07.jpg",
              ]
            },
            {
              text: `Em caso de recorrência de uma condicionante, o sistema disponibiliza algumas opções (mensal, bimestral, semestral, anual, ...). Após a conclusão da primeira recorrência, mediante atribuição de uma data de protocolo, o sistema criará a quantidade de recorrências necessárias até o fim da validade da licença;`,
              imgs: [
                "step5-08.jpg",
              ]
            }
          ]
        },
        {
          title: 'Etapa 6 – Gestão de Resíduos',
          isCollapsed: false,
          topics: [
            {

              text: `O primeiro passo é adicionar um empreendimento à gestão de resíduos selecionando o botão “adicionar” no canto superior direito da tela. Em seguida o usuário deve atribuir um Código e um Nome (ambos os campos são de livre preenchimento, à critério do usuário), selecionar um dos empreendimentos previamente cadastros e atribuir um gestor principal. Além destes campos, é também obrigatório o preenchimento da data de início da gestão e o gestor principal. Os demais campos não são de preenchimento obrigatório.`,
              imgs: [
                "step6-01.jpg",
                "step6-02.jpg",
              ]
            },
            {
              text: `O ambiente de cada empresa, em que a gestão de resíduos irá ocorrer, possui quatro ambas. Na aba “Dados Gerais”,  o usuário pode alterar os campos a qualquer momento, clicando no botão editar. Na aba “Inventário” o usuário deverá cadastrar todos os tipo de resíduos gerados por aquele empreendimento. Na aba “Operação” o usuário poderá cadastrar a destinação de resíduos realizada para cada tipo do inventário. Os campos para preenchimento são os mesmos campos de uma MTR – Manifesto de Transporte de Resíduos.`,
              imgs: [
                "step6-03.jpg",
              ]
            },
            {
              text: `Na aba “Inventário”  será mostrada uma lista dos resíduos cadastrados , sendo que na última coluna será mostrada a quantidade de “operações” realizadas para aquele resíduo. Para cadastrar um novo resíduo é necessário o preenchimento dos campos obrigatórios`,
              imgs: [
                "step6-04.jpg",
                "step6-05.jpg",
              ]
            },
            {
              text: `Na aba “Operações”  será mostrada uma lista dos resíduos efetivamente destinados, sendo que na última coluna será mostrada a quantidade de “operações” realizadas para aquele resíduo. Para cadastrar uma nova operação é necessário selecionar o resíduo do inventário a ser destinado e preencher os campos obrigatórios.`,
              imgs: [
                "step6-06.jpg",
                "step6-07.jpg",
              ]
            }
          ]
        },
      ],
    }
  },
  methods: {
    toggleCollapse(category) {
      const updatedCategory = { ...category }
      updatedCategory.isCollapsed = !updatedCategory.isCollapsed
      this.categories.splice(this.categories.indexOf(category), 1, updatedCategory)
    },
  },
}
</script>
