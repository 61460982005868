<template>
  <ImportWaste />
</template>

<script>
import ImportWaste from '@/modules/file-import/FileImportWaste.vue'

export default {
  components: { ImportWaste },
}
</script>
