<template>
  <ImportLicenseConditions />
</template>

<script>
import ImportLicenseConditions from '@/modules/file-import/FileImportLicenseConditions.vue'

export default {
  components: { ImportLicenseConditions },
}
</script>
