<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab
        title="Dados gerais"
        active
      >
        <UserManagementGeneralData />
      </b-tab>

      <b-tab />
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import UserManagementGeneralData from '@/views/user-management/UserManagementGeneralData.vue'

export default {
  components: {
    BTabs, BTab, UserManagementGeneralData,
  },
}
</script>

<style>

</style>
