<template>
  <help />
</template>

<script>
import Help from '@/modules/help/Help.vue'

export default {
  components: { Help },
}
</script>
