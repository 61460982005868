<template>
  <div class="company-page">
    <b-input-group class="input-group-merge round search-input-filter">
      <b-input-group-prepend
        is-text
      >
        <VSIcons
          name="Search"
          color="#7D8E9E"
          width="18"
          height="18"
        />
      </b-input-group-prepend>
      <b-form-input
        id="filter-input"
        v-model="filter"
        placeholder="Buscar..."
        type="search"
      />
    </b-input-group>
    <b-table
      id="company-table"
      ref="companyTable"
      class="details-table mt-4"
      :items="items"
      :fields="fields"
      hover
      responsive
      details-td-class="align-middle"
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      selectable
      select-mode="single"
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
      @row-selected="getSelectedRow"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template #head(select)>
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll('tickets', 'Tickets')"
        />
      </template>
      <template #cell(select)="row">
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="setItensToDelete({ itens: selected, api: 'feedback', fetchTable: 'Feedback' })"
        />
      </template>

      <template #cell(titulo)="row">
        <div class="d-flex align-items-center justify-content-center">
          <span class="validade">{{ row.item.subject }}</span>
        </div>
      </template>

      <template #cell(ultima-atualizacao)="row">
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <VSIcons
              name="calendar"
              height="18"
            />
          </div>
          <span class="validade">{{ getLastUpdate(row.item.last_update) }}</span>
        </div>
      </template>

      <template #cell(data-abertura)="row">
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <VSIcons
              name="calendar"
              height="18"
            />
          </div>
          <span class="validade">{{ getCreatedDate(row.item.createdAt) }}</span>
        </div>
      </template>
      <template #cell(status)="row">
        <div class="d-flex align-items-center justify-content-center">
          <span
            class="status"
            :class="classBadge(row.item.status)"
          >{{ row.item.status }}</span>
        </div>
      </template>
      <template #cell(protocolo)="row">
        {{ row.item.protocol }}
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="company-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
    <VSModal
      name="TicketChat"
      width="98%"
      :properties="ticketContent"
    />
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupPrepend, BFormInput, BTable, BFormCheckbox, BSpinner, BPagination,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BTable,
    VSIcons,
    BFormCheckbox,
    BSpinner,
    BPagination,
    VSModal,
  },
  mixins: [
    tableConfig,
  ],
  data() {
    return {
      ticketContent: {},
      fields: [
        {
          label: '',
          key: 'select',
        },
        {
          label: 'Data de abertura',
          key: 'data-abertura',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Protocolo',
          key: 'protocolo',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Título',
          key: 'titulo',
          sortable: true,
          tdClass: 'align-middle',
        },

        {
          label: 'Última atualização',
          key: 'ultima-atualizacao',
          sortable: true,
          tdClass: 'align-middle',
        },

        {
          label: 'Status',
          key: 'status',
          sortable: true,
          tdClass: 'align-middle',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall', items: 'tickets/getUserTickets' }),
  },
  created() {
    this.getTickets()
  },
  methods: {
    ...mapActions({ getTickets: 'tickets/fetchUserTickets' }),
    classBadge(status) {
      // eslint-disable-next-line no-nested-ternary
      return status === 'Aberto' ? 'open' : status === 'Fechado' ? 'closed' : 'resolved'
    },
    getSelectedRow(item) {
      this.ticketContent = {
        createdAt: item[0].createdAt,
        protocol: item[0].protocol,
        last_update: item[0].last_update,
        status: item[0].status,
        description: item[0].description,
        messages: item[0].messages,
      }
      this.showModal('TicketChat')
    },
    getLastUpdate(date) {
      const correctDate = new Date(date)
      return correctDate.toLocaleString('pt-br')
    },
    getCreatedDate(date) {
      const correctDate = new Date(date)
      return correctDate.toLocaleString('pt-br')
    },
    ...mapMutations({
      setItensToDelete: 'app/SET_ITENS_DELETE',
    }),
  },
}
</script>

<style scoped>
.status {
  padding: 3px 9px;
  border-radius: 5px;
}
.open {
  color: #ffc700;
  background-color: #fff8dd;
}
.closed {
  background: rgba(224, 9, 73, 0.21);
  color: rgb(255, 0, 0);
}

.resolved {
  background: rgba(26, 183, 127, 0.21);
  color: #18AC81;
}
</style>
