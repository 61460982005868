<template>
  <ImportLicensingDocuments />
</template>

<script>
import ImportLicensingDocuments from '@/modules/file-import/FileImportLicensingDocuments.vue'

export default {
  components: { ImportLicensingDocuments },
}
</script>
