<template>
  <ImportPublicAgencies />
</template>

<script>
import ImportPublicAgencies from '@/modules/file-import/FileImportPublicAgencies.vue'

export default {
  components: { ImportPublicAgencies },
}
</script>
