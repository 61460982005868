<template>
  <div>
    <b-row>
      <b-col md="12">
        <h6>Como fazer a importação?</h6>
        <p><strong>Passo 1:</strong> Clique no botão exemplo de tabela e faça o download da planilha de exemplo</p>
        <p><strong>Passo 2:</strong> Preencha as linhas de acordo com as colunas</p>
        <p><strong>Passo 3:</strong> Selecione um arquivo ou solte no campo de Upload de arquivo</p>
        <p><strong>Passo 4:</strong> Clique no botão Enviar e submeta o arquivo escolhido para processamento e cadastro</p>
        <li> Existem colunas com preenchimento obrigatório que são informadas abaixo e as demais colunas são de preenchimento opcional. Caso deseje modificar ou excluir alguma informação da tabela, exclua a linha e insira novamente as informações</li>
        <br>
        <p>
          <b>No caso de um fornecedor com CNPJ as colunas obrigatórias são: </b>
          <ul class="itens">
            <li>CNPJ/CPF(apenas números)</li>
            <li>NOME FANTASIA</li>
            <li>RAZÃO SOCIAL</li>
            <li>SERVIÇO</li>
          </ul>
        </p>
        <p>
          <b>No caso de um fornecedor com CPF as colunas obrigatórias são: </b>
          <ul class="itens">
            <li>CNPJ/CPF(apenas números)</li>
            <li>NOME COMPLETO</li>
            <li>SERVIÇO</li>
          </ul>
        </p>
        <BButton
          variant="primary"
          class="button"
          @click="downloadFile"
        >
          <div class="d-inline-block">
            <p>Exemplo de Tabela</p>
          </div>
          <div class="d-inline-block ml-2">
            <VSIcons
              name="Import2"
              color="#FFFFFF"
            />
          </div>
        </BButton>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="12"
        class="mt-4"
      >
        <h6 class="d-inline-block">
          Upload do arquivo
        </h6>
      </b-col>
      <b-col md="6">
        <b-form-file
          v-model="selectedFile"
          placeholder="Selecione um arquivo ou solte aqui..."
          drop-placeholder="Solte o arquivo aqui..."
          accept=".xlsx"
        />
        <b-col
          md="12"
          class="px-0"
        >
          <BButton
            variant="primary"
            class="button float-right mt-2"
            :disabled="!selectedFile"
            @click="createUpload()"
          >
            <div class="d-inline-block">
              <p>Enviar Arquivo</p>
            </div>
          </BButton>
        </b-col>
      </b-col>
    </b-row>
    <VSModal
      width="700"
      name="ImportError"
      :draggable="false"
      :conflict-list="conflicts"
    />
  </div>
</template>

<style lang="scss" scoped>
.itens li {
 //diminui tamanho da fonte
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: #4F4F4F;
  margin-left: 20px;
}
</style>

<script>
import {
  BButton, BRow, BCol, BFormFile,
} from 'bootstrap-vue'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'
import axios from '@/libs/axios'

export default {
  components: {
    VSIcons,
    BButton,
    BRow,
    BCol,
    BFormFile,
    VSModal,
  },
  data() {
    return {
      selectedFile: null,
      conflicts: [],
    }
  },
  methods: {
    async downloadFile() {
      const url = `${process.env.VUE_APP_API}bucket-s3/download/ec68c5bc8be1de81d968a3996bbce5d1-fornecedor.xlsx`
      const response = await fetch(url)
      const blob = await response.blob()
      const urlObject = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = urlObject
      link.download = 'fornecedor.xlsx'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    createUpload() {
      const formData = new FormData()
      formData.append('file', this.selectedFile)
      axios
        .post('system/supplier/uploadFornecedor', formData)
        .then(response => {
          if (response.status === 201) {
            if (response.data.conflictSupplier) {
              this.conflicts = response.data.conflictSupplier
              this.showModal('ImportError')
            } else if (response.data.successSupplier.length > 0) {
              this.toast({
                title: response.data.message,
                text: 'Cadastro de fornecedores realizado com sucesso.',
                variant: 'success',
              })
              this.$router.push('/fornecedor')
            } else {
              this.toast({
                title: 'Erro',
                text: 'Erro ao realizar cadastro de fornecedores.',
                variant: 'erro',
              })
            }
          } else if (response.status === 400) {
            this.toast({
              title: response.data.message,
              text: 'Erro ao realizar cadastro de fornecedores.',
              variant: 'erro',
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
