<template>
  <ImportCompany />
</template>

<script>
import ImportCompany from '@/modules/file-import/FileImportCompany.vue'

export default {
  components: { ImportCompany },
}
</script>
