<template>
  <ImportLicenseType />
</template>

<script>
import ImportLicenseType from '@/modules/file-import/FileImportLicenseType.vue'

export default {
  components: { ImportLicenseType },
}
</script>
