var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"btn-actions-content d-flex"},[(_vm.edit)?_c('b-button',{staticClass:"d-flex align-items-center mr-3 button-cancel",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.setEdit(false)}}},[_c('VSIcons',{staticClass:"pr-2",attrs:{"name":"CloseSquare","width":"22","height":"22"}}),_c('span',[_vm._v("Cancelar")])],1):_vm._e(),(!_vm.edit && _vm.userAccount != 'Master')?_c('b-button',{staticClass:"btn-grad d-flex align-items-center",on:{"click":function($event){return _vm.setEdit(true)}}},[_c('VSIcons',{staticClass:"pr-2",attrs:{"name":"Edit","width":"22","height":"22"}}),_c('span',[_vm._v("Editar")])],1):_c('b-button',{staticClass:"btn-grad d-flex align-items-center",on:{"click":function($event){return _vm.editMyUser()}}},[_c('VSIcons',{staticClass:"pr-2",attrs:{"name":"TickSquare","width":"22","height":"22"}}),_c('span',[_vm._v("Salvar")])],1)],1),_c('h6',[_vm._v("Tipo de conta")]),(this.user_id == 1)?_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.options,"aria-describedby":ariaDescribedby,"name":"radio-inline","disabled":!_vm.edit},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}}],null,false,629796666)}):_vm._e(),_c('h6',[_vm._v("Dados do usuário")]),_c('validation-observer',{ref:"form"},[_c('b-form',{staticClass:"mt-3"},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"nome completo","rules":"required|alpha_spaces|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome completo"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"nome-completo","disabled":!_vm.edit,"type":"text"},model:{value:(_vm.userData.full_name),callback:function ($$v) {_vm.$set(_vm.userData, "full_name", $$v)},expression:"userData.full_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"E-mail"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"email","disabled":"","type":"email"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[(_vm.user_id != 1)?_c('div',[(_vm.selected === 'pf')?_c('validation-provider',{attrs:{"name":"cpf","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CPF"}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"id":"cpf","disabled":!_vm.edit,"type":"text"},model:{value:(_vm.userData.cpf),callback:function ($$v) {_vm.$set(_vm.userData, "cpf", $$v)},expression:"userData.cpf"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,284258313)}):_vm._e()],1):_c('div',[(_vm.selected === 'pf')?_c('validation-provider',{attrs:{"name":"cpf","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CPF. "}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"id":"cpf","disabled":!_vm.edit,"type":"text"},model:{value:(_vm.accountCpf),callback:function ($$v) {_vm.accountCpf=$$v},expression:"accountCpf"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,93778237)}):_vm._e(),(_vm.selected === 'pj')?_c('validation-provider',{attrs:{"name":"cnpj","rules":"required|cnpj"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CNPJ."}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],attrs:{"id":"cnpj","disabled":!_vm.edit,"type":"text"},model:{value:(_vm.accountCpf),callback:function ($$v) {_vm.accountCpf=$$v},expression:"accountCpf"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3864611517)}):_vm._e()],1)]),_c('b-col',[_c('validation-provider',{attrs:{"name":"Documento de Identificação","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Documento de Identificação"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"documento","disabled":!_vm.edit,"type":"text"},model:{value:(_vm.userData.identification_document),callback:function ($$v) {_vm.$set(_vm.userData, "identification_document", $$v)},expression:"userData.identification_document"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Orgão Emissor","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Órgão Emissor"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"emissor","disabled":!_vm.edit,"type":"text"},model:{value:(_vm.userData.document_issuer),callback:function ($$v) {_vm.$set(_vm.userData, "document_issuer", $$v)},expression:"userData.document_issuer"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"celular","rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Celular"}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.maskTelephone(_vm.userData.telephone)),expression:"maskTelephone(userData.telephone)"}],attrs:{"id":"Celular","disabled":!_vm.edit,"type":"text"},model:{value:(_vm.userData.telephone),callback:function ($$v) {_vm.$set(_vm.userData, "telephone", $$v)},expression:"userData.telephone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"aniversário","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Aniversário"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"aniversario","disabled":!_vm.edit,"type":"date"},model:{value:(_vm.userData.birthday),callback:function ($$v) {_vm.$set(_vm.userData, "birthday", $$v)},expression:"userData.birthday"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Como nos conheceu"}},[_c('b-input-group',[_c('b-form-select',{attrs:{"id":"conheceu","options":['Facebook', 'Instagram', 'Linkedin', 'Google', 'Youtube'],"disabled":!_vm.edit}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }