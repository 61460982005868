<script>/* eslint-disable */</script>
<template>
  <div>
    <div class="btn-actions-content d-flex">
      <b-button v-if="edit" class="d-flex align-items-center mr-3 button-cancel" variant="outline-primary"
        @click="setEdit(false)">
        <VSIcons name="CloseSquare" width="22" height="22" class="pr-2" />
        <span>Cancelar</span>
      </b-button>
      <b-button v-if="!edit && userAccount != 'Master'" class="btn-grad d-flex align-items-center" @click="setEdit(true)">
        <VSIcons name="Edit" width="22" height="22" class="pr-2" />
        <span>Editar</span>
      </b-button>
      <b-button v-else class="btn-grad d-flex align-items-center" @click="editMyUser()">
        <VSIcons name="TickSquare" width="22" height="22" class="pr-2" />
        <span>Salvar</span>
      </b-button>
    </div>
    <h6>Tipo de conta</h6>
    <b-form-group v-if="this.user_id == 1" v-slot="{ ariaDescribedby }">
      <b-form-radio-group v-model="selected" :options="options" :aria-describedby="ariaDescribedby" name="radio-inline"
        :disabled="!edit" />
    </b-form-group>
    <h6>Dados do usuário</h6>
    <validation-observer ref="form">
      <b-form class="mt-3">
        <b-row>
          <b-col md="3">
            <validation-provider v-slot="{ errors }" name="nome completo" rules="required|alpha_spaces|max:50">
              <b-form-group label="Nome completo">
                <b-input-group>
                  <b-form-input id="nome-completo" v-model="userData.full_name" :disabled="!edit" type="text" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider v-slot="{ errors }" name="e-mail" rules="required|email">
              <b-form-group label="E-mail">
                <b-input-group>
                  <b-form-input id="email" v-model="userData.email" disabled type="email" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <div v-if="user_id != 1">
              <validation-provider v-if="selected === 'pf'" v-slot="{ errors }" name="cpf" rules="required|cpf">
                <b-form-group label="CPF">
                  <b-input-group>
                    <b-form-input id="cpf" v-model="userData.cpf" v-mask="'###.###.###-##'" :disabled="!edit"
                      type="text" />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </div>
            <div v-else>
              <validation-provider v-if="selected === 'pf'" v-slot="{ errors }" name="cpf" rules="required|cpf">
                <b-form-group label="CPF. ">
                  <b-input-group>
                    <b-form-input id="cpf" v-model="accountCpf" v-mask="'###.###.###-##'" :disabled="!edit" type="text" />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <validation-provider v-if="selected === 'pj'" v-slot="{ errors }" name="cnpj" rules="required|cnpj">
                <b-form-group label="CNPJ.">
                  <b-input-group>
                    <b-form-input id="cnpj" v-model="accountCpf" v-mask="'##.###.###/####-##'" :disabled="!edit"
                      type="text" />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </div>
          </b-col>
          <b-col>
            <validation-provider v-slot="{ errors }" name="Documento de Identificação" rules="max:50">
              <b-form-group label="Documento de Identificação">
                <b-input-group>
                  <b-form-input id="documento" v-model="userData.identification_document" :disabled="!edit" type="text" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider v-slot="{ errors }" name="Orgão Emissor" rules="max:50">
              <b-form-group label="Órgão Emissor">
                <b-input-group>
                  <b-form-input id="emissor" v-model="userData.document_issuer" :disabled="!edit" type="text" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="2">
            <validation-provider v-slot="{ errors }" name="celular" rules="required|phone">
              <b-form-group label="Celular">
                <b-input-group>
                  <b-form-input id="Celular" v-model="userData.telephone" v-mask="maskTelephone(userData.telephone)"
                    :disabled="!edit" type="text" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2">
            <validation-provider v-slot="{ errors }" name="aniversário" rules="required">
              <b-form-group label="Aniversário">
                <b-input-group>
                  <b-form-input id="aniversario" v-model="userData.birthday" :disabled="!edit" type="date" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="2">
            <b-form-group label="Como nos conheceu">
              <b-input-group>
                <b-form-select id="conheceu" :options="['Facebook', 'Instagram', 'Linkedin', 'Google', 'Youtube']"
                  :disabled="!edit" />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm, BInputGroup, BFormInput, BFormGroup, BRow, BCol, BButton, BFormSelect, BFormRadioGroup,
} from 'bootstrap-vue'
import { mapGetters, mapMutations } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import users from '@/services/users'
import { maskPhone } from '@/@core/mixins/ui/forms'
import api from '@/libs/axios'
import { cpf } from '@/@core/utils/validations/validations'

export default {
  components: {
    BForm, BInputGroup, BFormInput, BFormGroup, BRow, BCol, BButton, BFormSelect, VSIcons, BFormRadioGroup,
  },
  mixins: [
    maskPhone,
  ],
  data() {
    return {
      userAccount: '',
      custumer_id_iugu: '',
      accountCpf: null,
      id_account_verdesys: null,
      user_id: null,
      isLegalPerson: null,
      userData: {},
      selected: '',
      options: [
        { text: 'Pessoa física', value: 'pf' },
        { text: 'Pessoa jurídica', value: 'pj' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      edit: 'app/getItemTableEdit',
      itemToEdit: 'app/getItemTableDetail',
      getUserData: 'auth/getUserData',
    }),
  },
  mounted() {
    const emailUser_infos = JSON.parse(localStorage.getItem('user_infos')).customer_email;
    const nameUser_infos = JSON.parse(localStorage.getItem('user_infos')).customer_name;
    const userIdAccount = JSON.parse(localStorage.getItem("id_account_verdesys"));
    const emailUser = JSON.parse(localStorage.getItem('user')).email;
    const nameUser = JSON.parse(localStorage.getItem('user')).full_name;
    const cpfUser = JSON.parse(localStorage.getItem('user')).cpf;
    const userid = JSON.parse(localStorage.getItem('user')).id;
    const role = JSON.parse(localStorage.getItem('user')).role;


    api.get(`/accounts/${userIdAccount}`).then((response) => {
      this.isLegalPerson = response.data.isLegalPerson;
      this.accountCpf = response.data.cpf;
      console.log('isLegalPerson', this.accountCpf)
      if (this.isLegalPerson === true && this.user_id == 1) {
        this.selected = 'pj'
      } else {
        this.selected = 'pf'
      }
    });

    // setTimeout(() => {
    //   if (emailUser_infos !== emailUser || nameUser_infos !== nameUser || cpfUser !== this.accountCpf) {
    //       if (userid === 1 && role !== 'Master' && this.userData.cpf != '') {          
    //         window.location.reload();        
    //       } 
    //     }
    // }, 2000)

    setTimeout(() => {
      if (emailUser_infos !== emailUser || nameUser_infos !== nameUser) {
        if (userid === 1 && role !== 'Master') {
          window.location.reload();
        }
      }
    }, 3000)

    api.get(`/accounts/${userIdAccount}`).then((response) => {
      this.accountCpf = response.data.cpf;
      console.log('isLegalPersonkkkkkk', this.accountCpf)
    });

  },

  beforeCreate() {
    if (this.user_id != 1) {
      this.selected = 'pf'
    }
  },

  created() {
    this.custumer_id_iugu = JSON.parse(localStorage.getItem("user_infos")).customer_id;
    this.id_account_verdesys = JSON.parse(localStorage.getItem("id_account_verdesys"));
    this.user_id = JSON.parse(localStorage.getItem("user")).id;
    const role = JSON.parse(localStorage.getItem("user")).role;
    this.verifyRouteAddNewItem()
    this.userData = this.getUserData
    this.userAccount = JSON.parse(localStorage.getItem('user')).role;

    const dataUtil = {
      costumer_id_iugu: this.custumer_id_iugu,
      name: this.userData.full_name,
      phone_prefix: Number(this.userData.telephone.substring(1, 3)),
      Phone: Number(this.userData.telephone.substring(5, 15).replace('-', '')),  
    }

    if (this.user_id === 1 && role != 'Master') {
      try {
        api.patch('/accounts/client-edit/' + this.id_account_verdesys, dataUtil)
          .then((response) => {
            console.log('response QUE DEU CERTO', response.data)
          })
      } catch (error) {
      }
    } else {
    }

  },
  methods: {
    patchCpf() {
      console.log('patchCpf', this.accountCpf)
      try {
        const id = JSON.parse(localStorage.getItem("id_account_verdesys"));
        const iugu = JSON.parse(localStorage.getItem("user_infos")).customer_id;
        api.patch('/accounts/client-edit/' + id, { cpf_cnpj: this.accountCpf, costumer_id_iugu: iugu })
          .then((response) => {
            console.log('response', response.data)
          })
      } catch (error) {
      }
    },
    createdMessage() {
      this.toast({
        title: 'Dados atualizados com sucesso.',
        text: 'Refaça o login para ver as alterações.',
        variant: 'success',
      })
    },

    ...mapMutations({ setEdit: 'app/SET_ITEM_TABLE_EDIT' }),
    editMyUser() {
      this.$refs.form.validate().then(success => {
        if (success) {
          users.editUser(this.userData).then(() => this.setEdit(false))
          if (this.user_id === 1) {
            this.patchCpf()
          }
          this.createdMessage()
          setTimeout(() => {
            localStorage.removeItem('user')
            localStorage.removeItem('user_infos')
            window.location.reload()
          }, 5000)
        }
      })
    },
  },
}
</script>
