<script>/* eslint-disable */</script>
<template>
  <div class="">
    <div class="btn-actions-content d-flex">
      <b-button
        class="btn-grad d-flex align-items-center btn-action mr-3"
        @click="createUser()"
      >
        <VSIcons
          name="AddSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="align-middle">Adicionar usuário</span>
      </b-button>
      <b-button
        class="btn-grad d-flex align-items-center btn-action"
      >
        <VSIcons
          name="Setting-4"
          width="22"
          height="22"
          class="pr-2"
        />
        <span
          class="align-middle"
          @click="$router.push({name: 'permissoes'})"
        >Gerenciar Permissões</span>
      </b-button>
    </div>
    <b-input-group class="input-group-merge round search-input-filter">
      <b-input-group-prepend
        is-text
      >
        <VSIcons
          name="Search"
          color="#7D8E9E"
          width="18"
          height="18"
        />
      </b-input-group-prepend>
      <b-form-input
        id="filter-input"
        v-model="filter"
        placeholder="Buscar..."
        type="search"
      />
    </b-input-group>
    <div class="users-table">
      <b-table
        id="users-table"
        class="module-table mt-4"
        :items="items"
        :fields="fields"
        hover
        responsive
        details-td-class="align-middle"
        :busy="apiInCall"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        empty-filtered-text="Não há resultados para sua pesquisa."
        empty-text="Não há registros para serem exibidos."
        show-empty
        @filtered="onFiltered"
      ><template #cell(actions)="row">
         <b-dropdown
           no-caret
           right
           variant="light"
           menu-class="dropdown-table-actions"
           toggle-class="button-dropdown-actions"
         >
           <template #button-content>
             <VSIcons
               name="Actions"
               width="5"
               height="22"
             />
           </template>
           <b-dropdown-item
             class="dropdown-table-item"
             @click="onRowSelected(row.item)"
           >
             <VSIcons
               name="Eye"
               width="21"
               height="21"
               color="#7D8E9E"
             />
             <span>
               Ver
             </span>
           </b-dropdown-item>
           <b-dropdown-divider />
           <b-dropdown-item
             class="dropdown-table-item"
             @click="onRowSelected(row.item)"
           >
             <VSIcons
               name="Edit"
               width="20"
               height="20"
               color="#7D8E9E"
             />
             <span>
               Editar
             </span>
           </b-dropdown-item>
           <b-dropdown-divider v-if="row.item.id != 1" />
           <b-dropdown-item
              v-if="row.item.id != 1"
             class="dropdown-table-item"
             @click="deleteItem(row.item)"
           >
             <VSIcons
               name="TrashSquare"
               width="21"
               height="21"
               color="#7D8E9E"
             />
             <span>
               Excluir
             </span>
           </b-dropdown-item>
         </b-dropdown>
       </template>
        <template #table-busy>
          <b-spinner variant="primary" />
        </template>
        <template #head(select)>
          <b-form-checkbox
            v-model="selected"
            :value="filteredItens"
            @change="toggleAll('system/waste/general', 'Waste')"
          />
        </template>
        <template #cell(select)="row">
          <b-form-checkbox
            v-model="selected"
            :value="row.item"
            @change="setItensToDelete({ itens: selected, api: 'system/users', fetchTable: 'Users' })"
          />
        </template>
        <template #cell(permissionName)="row">
          <b-badge class="bg-primary-light permission">
            <span class="font-weight-500 font-13">
              {{ row.item.permission.name }}
            </span>
          </b-badge>
        </template>
        <template
          #cell(isActive)="row"
        >
          <b-form-checkbox
            v-model="row.item.isActive"
            switch
            size="lg"
            class="status-checkbox"
            @change="toggleStatus(row.item)"
          />
        </template>

      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="enterprise-table"
      >
        <template #prev-text>
          <VSIcons name="ArrowLeft" />
        </template>
        <template #next-text>
          <VSIcons name="ArrowRight" />
        </template>
      </b-pagination>
      <VSModal
        :properties="userAltenateState"
        name="AlternanteUserState"
      />
      <VSModal
        width="570"
        :properties="itemToDelete"
        name="DeleteOneItem"
      />
    </div>
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupPrepend, BFormInput, BButton, BTable, BFormCheckbox, BSpinner, BPagination, BDropdown, BDropdownItem, BDropdownDivider, BBadge,
} from 'bootstrap-vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'

export default {
  components: {
    VSIcons,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    VSModal,
    BTable,
    BFormCheckbox,
    BSpinner,
    BPagination,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
  },
  mixins: [
    tableConfig,
  ],
  data() {
    return {
      userAltenateState: {},
      itemToDelete: { api: '/system/users', fetchTable: 'Users' },
      fields: [
        {
          label: '',
          key: 'select',
        },
        {
          label: 'ID',
          key: 'id',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Nome',
          key: 'full_name',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'E-mail',
          key: 'email',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'CPF',
          key: 'cpf',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Telefone',
          key: 'telephone',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Permissão',
          key: 'permissionName',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Status',
          key: 'isActive',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'align-middle',
        },

      ],
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall', items: 'users/getUsers' }),
  },
  async created() {
    await this.fetchUsers()
  },
  methods: {
    ...mapActions({ fetchUsers: 'users/fetchUsers' }),
    ...mapMutations({ setSubTitle: 'app/SET_SUBTITLE', setItemEdit: 'app/SET_ITEM_TABLE_EDIT' }),
    createUser() {
      this.setSubTitle('')
      this.setItemEdit(true)
      this.$router.push('gerenciamento-de-usuarios/criar/usuarios')
    },
    toggleStatus(row) {
      this.userAltenateState = row
      this.showModal('AlternanteUserState')
    },
    onRowSelected(items) {
      if (items.id != 1 ){
        this.$router.push(`/gerenciamento-de-usuarios/${items.id}/usuarios`)
      }else{
        this.$router.push(`/minha-conta`)
      }
    },
    deleteItem(row) {
      this.itemToDelete.item = row
      this.showModal('DeleteOneItem')
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-action {
  white-space: nowrap;
  min-width: 190px;
  &:nth-child(2) {
    min-width: 220px;
  }
}
</style>
