import { render, staticRenderFns } from "./UserPermission.vue?vue&type=template&id=d32a2f8c&scoped=true&"
import script from "./UserPermission.vue?vue&type=script&lang=js&"
export * from "./UserPermission.vue?vue&type=script&lang=js&"
import style0 from "./UserPermission.vue?vue&type=style&index=0&id=d32a2f8c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d32a2f8c",
  null
  
)

export default component.exports