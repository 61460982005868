<template>
  <div>
    <validation-observer ref="form">
      <b-row>
        <b-col md="12">
          <h6>Como fazer a importação?</h6>
          <p><strong>Passo 1:</strong> Verifique se as linhas estão preenchidas de acordo com as colunas</p>
          <p><strong>Passo 2:</strong> Selecione um arquivo ou solte no campo de Upload de arquivo</p>
          <p><strong>Passo 3:</strong> Clique no botão Enviar e submeta o arquivo escolhido para processamento e cadastro</p>
          <li> Existem colunas com preenchimento obrigatório que são informadas abaixo e as demais colunas são de preenchimento opcional. Caso deseje modificar ou excluir alguma informação da tabela, exclua a linha e insira novamente as informações</li>
          <br>
          <p>
            <b>As colunas obrigatórias são: </b>
            <ul class="itens">
              <li>CÓDIGO DO RESÍDUO</li>
              <li>NÚMERO DO MTR</li>
              <li>TECNOLOGIA</li>
              <li>DATA DE RECEBIMENTO</li>
              <li>PESO LIQUIDO/QUANTIDADE</li>
              <li>UNIDADE</li>
            </ul>
          </p>
        </b-col>
        <b-col
          md="3"
        >
          <validation-provider
            v-slot="{ errors }"
            name="orgao-ambiental-estado"
            rules="required"
          >
            <b-form-group label="Órgão ambiental/Estado">
              <b-input-group>
                <b-form-select
                  id="orgao-ambiental-estado"
                  v-model="agenciesState"
                  :options="optionsAgenciesState"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
    <b-row>
      <b-col
        md="12"
      >
        <h6 class="d-inline-block">
          Upload do arquivo
        </h6>
      </b-col>
      <b-col md="6">
        <b-form-file
          v-model="selectedFile"
          placeholder="Selecione um arquivo ou solte aqui..."
          drop-placeholder="Solte o arquivo aqui..."
          accept=".xls, .xlsx"
        />
        <b-col
          md="12"
          class="px-0"
        >
          <BButton
            variant="primary"
            class="button float-right mt-2"
            :disabled="!selectedFile"
            @click="createUpload()"
          >
            <div class="d-inline-block">
              <p>Enviar Arquivo</p>
            </div>
          </BButton>
        </b-col>
      </b-col>
    </b-row>
    <VSModal
      width="700"
      name="ImportError"
      :draggable="false"
      :conflict-list="conflicts"
    />
  </div>
</template>

<style lang="scss" scoped>
.itens li {
 //diminui tamanho da fonte
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: #4F4F4F;
  margin-left: 20px;
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BButton, BRow, BCol, BFormFile, BFormGroup, BFormSelect, BInputGroup,
} from 'bootstrap-vue'
import VSModal from '@/components/modals/VSModal.vue'
import axios from '@/libs/axios'
import { required } from '@/@core/utils/validations/validations'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormFile,
    VSModal,
    BFormGroup,
    BFormSelect,
    BInputGroup,
  },
  data() {
    return {
      required,
      selectedFile: null,
      conflicts: [],
      agenciesState: '',
    }
  },
  computed: {
    ...mapGetters({
      optionsAgenciesState: 'app/getOptionsAgenciesState',
    }),
  },
  created() {
    if (this.optionsAgenciesState.length > 0) {
      this.agenciesState = this.optionsAgenciesState[0].value
    }
  },
  methods: {
    ...mapActions({
      fetchAndActivateOperationsTab: 'waste/fetchAndActivateOperationsTab',
    }),
    createUpload() {
      this.$refs.form.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('file', this.selectedFile)
          axios
            .post(`system/waste/operation/uploadOperacao/${this.$route.params.id}/${this.agenciesState}`, formData)
            .then(response => {
              if (response.status === 201) {
                if (response.data.conflictOperations) {
                  this.conflicts = response.data.conflictOperations
                  this.showModal('ImportError')
                } else if (response.data.successOperations.length > 0) {
                  this.toast({
                    title: response.data.message,
                    text: 'Cadastro de operações realizado com sucesso.',
                    variant: 'success',
                  })
                  this.fetchAndActivateOperationsTab(true)
                  this.$router.push(`/residuos/${this.$route.params.id}/detalhes`)
                } else {
                  this.toast({
                    title: 'Erro',
                    text: 'Erro ao realizar cadastro de operações.',
                    variant: 'erro',
                  })
                }
              } else if (response.status === 400) {
                this.toast({
                  title: response.data.message,
                  text: 'Erro ao realizar cadastro de órgãos licenciadores.',
                  variant: 'erro',
                })
              }
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          console.log('erro')
        }
      })
    },
  },
}
</script>
